import React, { useState, useEffect } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import EventLog from "./EventLog/";
import Exceptions from "./EventLog/exceptions";
import CustomNotifications from "./EventLog/CustomNotifications";
import styles from "./styles";
import { useSelector } from "react-redux";
import { TabsGenerator } from "../../../../Common";
import AlertSettings from "./EventLog/AlertSettings";


const tabsList = [
  {
    label: "Alerts",
    value: "stock_alerts",
    component:<EventLog/>
  },

  // {
  //   label: "Exceptions",
  //   value: "exceptions",
  //   component:<Exceptions/>
  // },
  {
    label: "Custom Notifications",
    value: "custom_notifications",
    component:<CustomNotifications/>
  },
  {
    label: "Email Settings",
    value: "settings",
    component:<AlertSettings/>
  },
];

const EventLogs = () => {
  const current_user = useSelector((state) => state.userReducer.current_user);
  const classes = styles();
  const [activeTab, setActiveTab] = useState("stock_alerts");
  const [tabs, setTabs] = useState([]);

  const handleTabChange = (_, newValue) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    if (current_user.type === "SU") {
      setTabs(
        tabsList.filter(
          (x) => ["stock_alerts", "exceptions", "settings"].indexOf(x.value) > -1
        )
      );
    }

   else if (
      current_user.type === "OP" ||
      current_user.type === "DU" ||
      current_user.type === "SA" ||
      current_user.type === "DE"
    ) {
      setTabs(
        tabsList.filter(
          (x) =>
            ["stock_alerts", "exceptions", "custom_notifications", "settings"].indexOf(
              x.value
            ) > -1
        )
      );
    }

   else if (current_user.type === "FO") {
      setTabs(
        tabsList.filter(
          (x) => ["stock_alerts", "custom_notifications", "settings"].indexOf(x.value) > -1
        )
      );
    }
    
    else {
      setTabs(
        tabsList.filter(
          (x) => ["stock_alerts", "custom_notifications", "settings"].indexOf(x.value) > -1
        )
      );
    }
  }, [current_user.type]);

  return (
    

    <>
      <TabsGenerator
        tabsList={tabs}
        activeTabs={activeTab}
      />
    </>
  );
};

export default EventLogs;
