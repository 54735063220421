import { combineReducers } from 'redux';
import routeReducer from './route-reducer';
import userReducer from './user-reducer';
import alertReducer from './alert-reducer';
import kittingPlanReducer from './kitting-plan';
import touchlessReducer from './touchless-reducer';
import qrPaymentReducer from './qr-payment-reducer';
import igtReducer from './igt-reducer';
import companySettingsReducer from "./company-settings-reducer";
import productsSettingsReducer from './product-settings-reducer';

const rootReducer = combineReducers({
  userReducer,
  igtReducer,
  routeReducer,
  alertReducer,
  kittingPlan: kittingPlanReducer,
  touchless: touchlessReducer,
  qrPayment: qrPaymentReducer,
  companySettingsReducer,
  productsSettingsReducer
})

export default rootReducer;
