import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function AsyncAutoComplete({
    onChange,
    value,
    options,
    required,
    label,
    loading,
    defaultOption, 
}) {
    const [defaultValue, setDefaultValue] = useState(null);

    useEffect(() => {
        if (!value && defaultOption) {
            setDefaultValue(defaultOption);
        }
    }, [value, defaultOption]);

    return (
        <Autocomplete
            id="filter-issue"
            getOptionLabel={(option) => option.label}
            options={options}
            onChange={(__, val) => onChange(val)}
            value={value || defaultValue}
            disableClearable={true}
            loading={loading}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    variant="outlined"
                    fullWidth
                    required={required}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
}
