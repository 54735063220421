
import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { useSnackbar } from "notistack";
import "./index.css";
import "./styles.css";
import styles from "../styles";
import _ from "lodash";
import moment from 'moment';
import {
  handleServerErrors
} from "../../utiles/helpers";
import {
  Grid
} from "@material-ui/core";
import { AllocateButton } from "../common/Buttons";
import ContentHeader from "../common/ContentHeader";
import InfoModal from "components/Common/AlertDialog/info_dialogue";
import { ExportButton, ImportButton } from "components/Common/Buttons";
import TableGenerator from "../../../../../Common/TableGenerator";
import AsyncAutoComplete from "components/Common/AsyncAutoComplete";
const QuestionsTab = () => {
  const classes = styles();
  const { enqueueSnackbar } = useSnackbar();

  // State and functions as defined above
  const [questions, setQuestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isMutating, setIsMutating] = useState(false);
  const [importError, setImportError] = useState([]);
  const [isInfo, setIsInfo] = useState(false);
  const [language, setLanguage] = useState(false);
  const [languageList, setLanguageList] = useState([])

  const MappedTypes = {
    'Email': "Email",
    "Date": "Date",
    "Mobile_Number": "Phone Number",
    "Number": "Number",
    "Text": "Text",
    "SSN": "SSN"
  }

  function getQuestionByLanguage(value) {
    // Iterate over the array to find the matching language
    console.log("value", value)
    for (const record of value) {
      let selectedLanguage = language ? language.label : 'English'
      if (record.language === selectedLanguage) {
        return record.question;
      }
    }
    return null;
  }

  const formFields = [
    {
      key: "questions",
      columnName: "Question",
      label: "Question",
      type: "text",
      visible: true,
      required: true,
      disableSorting: true,
      render: (value) => value ? getQuestionByLanguage(value) : "---",
    },
    {
      key: "question_type",
      columnName: "Question Type",
      label: "Question type",
      visible: true,
      required: true,
      type: "text",
      disableSorting: true,
      render: (value) => value || "---",
    },
    {
      key: "order",
      columnName: "Order",
      label: "Order",
      visible: true,
      required: true,
      type: "select",
      disableSorting: true,
      render: (value) => value || "---",
    },
  ];


  const handleSave = () => {
    setIsLoading(true)
    setIsSaving(true)
    const question_data = {
      "questions": questions
    }
    const post_url = `ui_form?sync_only=True`
    window.axiosIns
      .post(post_url, question_data)
      .then(() => {
        enqueueSnackbar("Form data questions synced successfully.");
        getQuestionForm();
        setIsLoading(false)
        setIsSaving(false)
      })
      .catch((err) => {
        if (err.response.data.detail) {
          enqueueSnackbar(err.response.data.detail);

        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not save form. Try again."
          );
        }

        setIsLoading(false)
        setIsSaving(false)
      })
      .then(() => {
        setIsLoading(false);

        setIsSaving(false)
      })
  };

  const getQuestionForm = async (data) => {
    try {
      setIsLoading(true);
      const params = {
        is_active: true,
        language: 'English',
        ordering : '-order'
      };
      const { data } = await window.axiosIns("/ui_form", { params });
      const question_data = []
      if (data?.results?.length > 0) {
        const questions_res = data?.results[0].question_list
        questions_res.map((question) => question_data.push({
          'id': question.id,
          'questions': question.questions,
          'question_type': question.question_type,
          'question': question.question,
          'order': question.order
        }))
      }

      setQuestions(question_data);
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getQuestionForm();
    getLanguages();
  }, [])


  const handleClick = (event) => {
    hiddenFileInput.current.value = null;
    hiddenFileInput.current.click();
  };

  const FormImport = async (event) => {

    const fileUploaded = event.target.files[0];
    const formData = new FormData();
    formData.append('template', fileUploaded);

    setIsMutating(true);
    window.axiosIns
      .post(`/ui_form/import_data`, formData)
      .then((resp) => {
        const { data: { data } } = resp;
        const message = resp.data.message;
        if (message === 'Import failed!') {
          setImportError(data); setIsInfo(true)
        };
        enqueueSnackbar(resp?.data?.message || "Questions Added successfully.", { autoHideDuration: 3000 });
        setTimeout(() => {
          setIsMutating(false);
        }, 200);
      })
      .catch((err) => {
        if (err.response.data.detail) {
          enqueueSnackbar(err.response.data.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not add Questions. Try again."
          );
        }
        setIsMutating(false);
      })
      .then(() => {
        getQuestionForm();
      });

  };

  const getLanguages = async () => {
    window
      .axiosIns("/languages", { params: { all: true, ordering: 'langauge' } })
      .then(({ data = {} }) => {
        let languages = data.results?.map(
          (x) => ({ label: x.langauge, value: x.id }))
        setLanguageList(languages);
      })
      .catch((err) => {
        if (err.detail) {
          enqueueSnackbar(err.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not get brands. Try again."
          );
        }
      });
  };

  const formDataExport = () => {

    window.axiosIns("/ui_form/export_data", {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Questions-Summary-${moment().format("MM-DD-YYYY")}.xlsx`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => console.log(error));
  }



  const hiddenFileInput = React.useRef(null);

  return (

    <div id="sa-modules-wrapper" className={classes.wrapper}>
      <ContentHeader
        // title="Orders"
        description="Add Questions for form here. You can add upto 10 questions."
      />

      <div className={classes.toolbar}>
      <Grid
  container
  spacing={2}
  alignItems="center"
  justifyContent="flex-start"
  className="pr-4 align-center"
>
  <Grid item>
    <AllocateButton
      label={ 'Sync'}
      onClick={() => handleSave()}
      loading={isSaving}
    />
  </Grid>
  <Grid item>
    <ExportButton
      label="Export"
      onClick={() => formDataExport()}
    />
  </Grid>
  <Grid item>
    <ImportButton
      label="Import"
      onClick={handleClick}
    />
    <input
      type="file"
      ref={hiddenFileInput}
      onChange={FormImport}
      style={{ display: "none" }}
      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
    />
  </Grid>
  <Grid item style={{ minWidth: '200px' }}>
    <AsyncAutoComplete
      onChange={(val) => setLanguage(val)}
      value={language}
      defaultOption={{label:'English', value:'English'}}
      options={languageList}
      required
      label="Select Language"
      disabled={isLoading}
    />
  </Grid>
</Grid>







      </div>

      <TableGenerator
        loader={isLoading}
        size="small"
        rowOnePage={10}
        data={questions}
        sensorTable={true}
        fields={formFields}
      />

      <InfoModal
        title="Import Errors"
        data={importError || []}
        open={isInfo}
        close={() => setIsInfo(false)}
      />

    </div>
  );
};

export default QuestionsTab;
