import { Chip, CircularProgress, Grid, TextField } from "@material-ui/core";
import CustomInputDatePicker from "components/Common/CustomInputDatePicker";
import moment from "moment";
import React, { useRef } from "react";
import { useState } from "react";
import { Autocomplete } from "@material-ui/lab";
import _ from "lodash";
import { ApplyButton, ExportButton } from "components/Common/Buttons";
import BarChart from "../BarChart";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { currencyFormatter, getFilterEndDate, getFilterStartDate } from "utils/helpers";
import { TableGenerator } from "components/Common";
import { useSnackbar } from "notistack";
import { handleServerErrors } from "helpers";
import AsyncAutoComplete from "../common/AsyncAutoComplete";
import { getTimeZoneDifference } from "utils/helpers";
import styles from "./styles";

const Analytics = () => {
  const user = useSelector((state) => state.userReducer?.current_user);
  const classes = styles();
  const { enqueueSnackbar } = useSnackbar();

  const [startDate, setStartDate] = useState(
    moment().subtract("months", 6).toDate()
  );
  const [endDate, setEndDate] = useState(new Date());
  const [selectedOperator, setSelectedOperator] = useState(
    user.type !== "SA" && user?.company?.company_id
      ? { value: user.company.company_id, label: user.company.company_name }
      : {
          value: "all",
          label: "All",
        }
  );
  const [operatorList, setOperatorList] = useState(
    user.id && user?.company?.company_name && user.type !== "SA"
      ? [
          {
            value: user.id,
            label: user.company.company_name,
          },
        ]
      : [{ value: "all", label: "All" }]
  );
  const [selectedLocation, setSelectedLocation] = useState({
    value: "all",
    label: "All",
  });
  const [selectedArea, setSelectedArea] = useState({
    value: "all",
    label: "All",
  });
  const [selectedVm, setSelectedVm] = useState([
    { value: "all", label: "All" },
  ]);
  const [locationList, setLocationList] = useState([
    { value: "all", label: "All" },
  ]);
  const [data, setData] = useState([]);
  const [areaList, setAreaList] = useState([{ value: "all", label: "All" }]);
  const [vmList, setVmList] = useState([]);
  const [operatorListLoading, setOperatorListLoading] = useState(false);
  const [areaListLoading, setAreaListLoading] = useState(false);
  const [locationListLoading, setLocationListLoading] = useState(false);
  const [vmListLoading, setVmListLoading] = useState(false);
  const [more, setMore] = useState(false);
  const [salesByLocation, setSalesByLocation] = useState([]);
  const [salesByProduct, setSalesByProduct] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [firstPage, setFirstPage] = useState(null);
  const [lastPage, setLastPage] = useState(null);
  const [dataCount, setDataCount] = useState(0);
  const [loader, setLoader] = useState(true);
  const [chartLoader, setChartLoader] = useState(true);
  const [ordering, setOrdering] = useState("");

  const fetchChartsData = async () => {
    try {
      setChartLoader(true);
      const params = {
        start_date: getFilterStartDate(startDate),
        end_date: getFilterEndDate(endDate),
        _scope: "AND",
        tz: getTimeZoneDifference(),
      };
      if (selectedOperator?.value !== "all")
        params.operator_id = selectedOperator?.value;

      if (selectedOperator?.value == "all")
        params.sub_operator = true;
      
      if (selectedArea?.value !== "all") params.area_id = selectedArea?.value;
      if (selectedLocation?.value !== "all")
        params.location_id = selectedLocation?.value;
      if (selectedVm[0]?.value !== "all")
        params.device_id = selectedVm?.map((item) => item?.value).join(",");
      
      const { data } = await window.axiosIns("orders/top_products_charts", {
        params,
      });
      let location = data?.sales_by_location?.length
        ? [
            ["Location", "Sale", {type: 'string', role: 'tooltip'}],
            ...data?.sales_by_location?.map((item) => [
              item.location_name,
              item.value,
              `${item.location_name}\nSale: $${currencyFormatter(item.value)}`
            ]),
          ]
        : [];
      let product = data?.sales_by_products?.length
        ? [
            ["Location", "Sale", {type: 'string', role: 'tooltip'}],
            ...data?.sales_by_products?.map((item) => [
              item.product_name,
              item.value,
              `${item.product_name}\nSale: $${currencyFormatter(item.value)}`
            ]),
          ]
        : [];

      setSalesByLocation(location);
      setSalesByProduct(product);
    } finally {
      setChartLoader(false);
    }
  };

  const exportExcel = () => {
    let params = {
      start_date: getFilterStartDate(startDate),
      end_date: getFilterEndDate(endDate),
      tz: getTimeZoneDifference(),
      all: true,
      export : true
    };
    if (selectedOperator?.value !== "all")
      params.operator_id = selectedOperator?.value;
    if (selectedArea?.value !== "all") params.area_id = selectedArea?.value;
    if (selectedLocation?.value !== "all")
      params.location_id = selectedLocation?.value;
    if (selectedVm[0]?.value !== "all")
      params.device_id = selectedVm?.map((item) => item?.value).join(",");
    window.axiosIns("/orders/top_products", {
      responseType: "arraybuffer",
      headers: {
        "Content-Type": "application/json",
      },
      params: params,
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Finance-Analytics-Summary-${moment().format("MM-DD-YYYY")}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => console.log(error));
  };

  const fetchTableData = async (rowsPerPage, order, page = 0) => {
    try {
      setLoader(true);
      const params = {
        start_date: getFilterStartDate(startDate),
        end_date: getFilterEndDate(endDate),
        tz: getTimeZoneDifference(),
        _scope: "AND",
        limit: rowsPerPage,
        page: page + 1,
      };
      if (order) params.ordering = order;
      if (selectedOperator?.value !== "all")
        params.operator_id = selectedOperator?.value;
      if (selectedArea?.value !== "all") params.area_id = selectedArea?.value;
      if (selectedLocation?.value !== "all")
        params.location_id = selectedLocation?.value;
      if (selectedVm[0]?.value !== "all")
        params.device_id = selectedVm?.map((item) => item?.value).join(",");
      const { data } = await window.axiosIns("orders/top_products", { params });
      setData(data.results);
      setDataCount(data.count);
      setNextPage(data.next);
      setPreviousPage(data.previous);
      setFirstPage(data.first);
      setLastPage(data.last);
    } catch (err) {
    } finally {
      setLoader(false);
    }
  };
  const changePage = async (url) => {
    setLoader(true);
    window.axiosIns
      .get(url)
      .then(({ data = {} }) => {
        setData(data.results);
        setDataCount(data.count);
        setNextPage(data.next);
        setPreviousPage(data.previous);
        setFirstPage(data.first);
        setLastPage(data.last);
      })
      .catch((err) => {
        setLoader(false);
        if (err.detail) {
          enqueueSnackbar(err.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not get data. Try again."
          );
        }
      })
      .finally(() => setLoader(false));
  };
  const fetchOperators = async (location) => {
    try {
      setOperatorListLoading(true);
      const { data } = await window.axiosIns("company", {
        params: { all: true, location, ordering: "business_name" },
      });

      const list = data?.data?.results;

      const dropdownMap = [
        { value: "all", label: "All" },
        ..._.map(list, ({ id, business_name }) => ({
          label: business_name,
          value: id,
        })),
      ];
      setOperatorList(dropdownMap);
    } catch (err) {
      console.log(err);
    } finally {
      setOperatorListLoading(false);
    }
  };
  const fetchAreas = async (selectedOperator) => {
    try {
      setAreaListLoading(true);
      const params = { all: true, ordering: "area_name" };
      if (selectedOperator && selectedOperator?.value !== "all")
        params.operator_id = selectedOperator.value;
      const { data } = await window.axiosIns("locations/areas", {
        params,
      });
      let areaList = [
        { label: "All", value: "all" },
        ..._.map(data?.results, ({ area_id, area_name, locations }) => ({
          label: area_name,
          value: area_id,
          locations,
        })),
      ];
      setAreaList(areaList);
      fetchLocations(areaList, null, selectedOperator);
    } catch (err) {
      console.log(err);
      fetchLocations([], null, selectedOperator);
    } finally {
      setAreaListLoading(false);
    }
  };
  const fetchLocations = async (areaList = [], area_id, selectedOperator) => {
    try {
      setLocationListLoading(true);
      if (area_id && area_id !== "all") {
        const list =
          _.find(areaList, (x) => x.value === area_id)?.locations || [];

        setLocationList([
          { label: "All", value: "all" },
          ..._.map(list, ({ location_id, location_name }) => ({
            label: location_name,
            value: location_id,
          })),
        ]);
      } else {
        if (selectedOperator && selectedOperator?.value !== "all") {
          let newLocation = [];
          const locationData = [
            ..._.map(areaList, ({ locations }) =>
              Array.prototype.push.apply(newLocation, locations)
            ),
          ];
          setLocationList([
            { label: "All", value: "all" },
            ..._.map(newLocation, ({ location_id, location_name }) => ({
              label: location_name,
              value: location_id,
            })),
          ]);
        } else {
          const { data } = await window.axiosIns("locations/locations", {
            params: { all: true, state: "all", ordering: "location_name" },
          });
          setLocationList([
            { label: "All", value: "all" },
            ..._.map(data?.results, ({ location_id, location_name }) => ({
              label: location_name,
              value: location_id,
            })),
          ]);
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLocationListLoading(false);
    }
  };
  const fetchVM = async (selectedOperator, selectedArea, selectedLocation) => {
    try {
      setVmListLoading(true);
      setVmList([]);
      const params = {
        all: true,
        ordering: "vm_name",
      };
      if (selectedOperator && selectedOperator.value !== "all")
        params.company_id = selectedOperator.value;
      if (selectedArea && selectedArea.value !== "all")
        params.area_id = selectedArea.value;
      if (selectedLocation && selectedLocation.value !== "all")
        params.location = selectedLocation.value;
      const { data } = await window.axiosIns("device", {
        params,
      });
      setVmList([
        { label: "All", value: "all" },
        ..._.map(data?.data?.results, ({ id, serial_number, vm_name }) => ({
          label: `${vm_name ? `${vm_name} ||` : ""} ${serial_number}`,
          value: id,
        })),
      ]);
    } catch (err) {
      console.log("err", err);
      setVmListLoading(false);
    } finally {
      setVmListLoading(false);
    }
  };

  const fields = [
    {
      key: "product_name",
      columnName: "Name",
      type: "text",
      visible: true,
      render: (val) => val || "---",
    },
    {
      key: "product_sku",
      columnName: "SKU",
      type: "text",
      visible: true,
      render: (val) => val || "---",
    },
    {
      key: "categories",
      columnName: "Product Categories",
      type: "text",
      visible: true,
      render: (val) => val || "---",
      disableSorting: true,
    },
    {
      key: "volume",
      columnName: "Sales Volume",
      type: "text",
      visible: true,
      render: (val) => val || "---",
    },
    {
      key: "sales_percentage",
      columnName: "Sales Share",
      type: "text",
      visible: true,
      render: (val) => val + "%" || "0%",
    },
    {
      key: "value",
      columnName: "Sales($)",
      type: "text",
      visible: true,
      render: (val) => currencyFormatter(val),
    },
  ];

  useEffect(() => {
    fetchOperators();
    fetchTableData(rowsPerPage, null, page);
    fetchChartsData();
    fetchAreas();
    fetchVM();
  }, []);

  const handleOperator = (val) => {
    setSelectedOperator(val);
    fetchAreas(val);
    fetchVM(val);
    setSelectedArea({ value: "all", label: "All" });
    setSelectedLocation({ value: "all", label: "All" });
    setSelectedVm([{ value: "all", label: "All" }]);
  };

  const handleArea = (val) => {
    setSelectedArea(val);
    fetchLocations(areaList, val.value, selectedOperator);
    fetchVM(selectedOperator, val);
    setSelectedLocation({ value: "all", label: "All" });
    setSelectedVm([{ value: "all", label: "All" }]);
  };

  const handleLocation = (val) => {
    setSelectedLocation(val);
    fetchVM(selectedOperator, selectedArea, val);
    setSelectedVm([{ value: "all", label: "All" }]);
  };

  return (
    <div id="sa-modules-wrapper" className={classes.wrapper}>
      <Grid spacing={1} container>
        <Grid item xs={12} sm={4} lg={2}>
          <div className="d-flex">
            <CustomInputDatePicker
              value={startDate}
              onChange={(date) => setStartDate(date)}
              className="mr-2"
              label="Start Date"
              maxDate={new Date(endDate).setDate(new Date(endDate).getDate())}
            />
            <CustomInputDatePicker
              value={endDate}
              onChange={(date) => setEndDate(date)}
              label="End Date"
              minDate={new Date(startDate)}
              maxDate={new Date()}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={4} lg={2}>
          <AsyncAutoComplete
            onChange={handleOperator}
            value={selectedOperator}
            loading={operatorListLoading}
            options={operatorList}
            required
            label="Operator"
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={2}>
          <AsyncAutoComplete
            onChange={handleArea}
            loading={areaListLoading}
            value={selectedArea}
            options={areaList}
            required
            label="Area"
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={2}>
          <AsyncAutoComplete
            onChange={handleLocation}
            value={selectedLocation}
            loading={locationListLoading}
            options={locationList}
            required
            label="Location"
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={2}>
          <div className="d-flex" style={{ gap: "8px" }}>
            <Autocomplete
              multiple
              style={{ width: "100%" }}
              id="tags-outlined"
              value={selectedVm}
              loading={vmListLoading}
              options={vmList || []}
              defaultValue={[{ label: "All", value: "all" }]}
              onChange={(event, newValue) => {
                const allPos = _.findIndex(newValue, (x) => x.value === "all");
                if (allPos === 0 && newValue?.length > 1) {
                  const data = _.filter(newValue, (x) => x.value !== "all");
                  setSelectedVm(data);
                } else if (allPos > 0) {
                  const data = _.filter(newValue, (x) => x.value === "all");
                  setSelectedVm(data);
                } else {
                  setSelectedVm(newValue);
                }
              }}
              getOptionLabel={(option) => option.label}
              renderTags={(tagValue, getTagProps) => {
                if (tagValue.length < 2) {
                  return tagValue.map((option, index) => (
                    <Chip
                      {...getTagProps({ index })}
                      label={`${option.label.slice(0, 8)}...`}
                    />
                  ));
                } else {
                  return (
                    <>
                      <div>
                        {(more ? tagValue : tagValue.slice(0, 1)).map(
                          (option, index) => (
                            <Chip
                              {...getTagProps({ index })}
                              label={`${option.label.slice(0, 8)}...`}
                            />
                          )
                        )}
                        {!more && (
                          <span
                            style={{
                              position: "absolute",
                              fontSize: "13px",
                              top: "20px",
                              cursor: "pointer",
                            }}
                            onClick={() => setMore(!more)}
                          >{`+${tagValue.length - 1} more`}</span>
                        )}
                      </div>
                      {more && (
                        <span
                          style={{
                            position: "static",
                            paddingLeft: "10px",
                            fontSize: "13px",
                            top: "20px",
                            cursor: "pointer",
                          }}
                          onClick={() => setMore(!more)}
                        >
                          show less
                        </span>
                      )}
                    </>
                  );
                }
              }}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Vending Machines"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {vmListLoading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={2} lg={1}>
          <ApplyButton
            style={{ height: 56, width: "100%" }}
            onClick={() => {
              fetchChartsData();
              fetchTableData(rowsPerPage, null, 0);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={2} lg={1}>
          <ExportButton
            label="Export"
            style={{ height: 56, width: "100%" }}
            onClick={() => exportExcel()}
          />
        </Grid>
      </Grid>
      <div>
        <Grid container alignItems="center" justifyContent="center">
          <Grid item xs={12} lg={6}>
            <BarChart
              loader={chartLoader}
              title="Sales by Product"
              data={salesByProduct.map(product=>product[0]?.length>20?[product[0].substring(0,20)+"...",product[1],product[2]]:product)}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <BarChart
              loader={chartLoader}
              title="Sales by Location"
              data={salesByLocation.map(location=>location[0]?.length>20?[location[0].substring(0,20)+"...",location[1],location[2]]:location)}
            />
          </Grid>
        </Grid>
      </div>
      <TableGenerator
        loader={loader}
        data={data}
        fields={fields}
        showSelectAll={false}
        sensorTable={true}
        backendPagination={true}
        disablePagination={false}
        onRowPerPageChange={(rows) => {
          setRowsPerPage(rows);
          setPage(0);
          fetchTableData(rows, null, 0);
        }}
        dataCount={dataCount}
        rowOnePage={rowsPerPage}
        currentPage={page}
        handleSortChange={(ordering) => {
          setOrdering(ordering);
          setPage(0);
          fetchTableData(rowsPerPage, ordering, 0);
        }}
        onPageChange={(page, direction) => {
          setPage(page);
          if (direction === "next") {
            changePage(nextPage);
          } else if (direction === "back") {
            changePage(previousPage);
          } else if (direction === "first") {
            changePage(firstPage);
          } else if (direction === "last") {
            changePage(lastPage);
          }
        }}
      />
    </div>
  );
};

export default Analytics;
