import React, { useEffect, useState } from "react";
import styles from "./styles";
import { Typography, withTheme } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import SvgIcon from "@material-ui/core/SvgIcon";
import {
  RefreshButton,
  ButtonIcon,
  AddButton,
  EditButton,
  DeleteButton,
} from "../../../../Common/Buttons";
import { CrudDialog, TableGenerator, SearchBox } from "../../../../Common";
import DeviceConnection from "../../../../Common/TableCellComponents/DeviceConnection";
import { CashService } from "../../../../../services/Api";
import { useSnackbar } from "notistack";
import {
  handleServerErrors,
  handleMultiFilterSearch,
} from "../../../../../utils/helpers";
import { MoveToInbox } from "@material-ui/icons";

const filterLabels = {
  ID: "id",
  serial_number: "device__serial_number",
  vm_name: "vm_name",
  operator: "operator",
  location: "location",
};

const sensorsFields = [
  "ID",
  "serial_number",
  "vm_name",
  "operator",
  "location",
];

const intialAddState = {
  quantity_1: 10,
  quantity_2: 10,
  quantity_5: 10,
  quantity_10: 4,
  quantity_20: 4,
  quantity_50: 2,
  quantity_100: 0,
  quantity_1c: 25,
  quantity_2c: 1,
  quantity_5c: 10,
  quantity_10c: 10,
  quantity_20c: 1,
  quantity_25c:10,
  quantity_50c: 1,
  quantity_100c: 0,
};
const intialBoxState = {
  totalAmount: 0,
  quantity_1: 0,
  quantity_2: 0,
  quantity_5: 0,
  quantity_10: 0,
  quantity_20: 0,
  quantity_50: 0,
  quantity_100: 0,
  quantity_1c: 0,
  quantity_2c: 0,
  quantity_5c: 0,
  quantity_10c: 0,
  quantity_20c: 0,
  quantity_25c:0,
  quantity_50c: 0,
  quantity_100c: 0,
};

const CashAcceptorApp = (props) => {
  const classes = styles();
  const [modulesList, setModulesList] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [page, setPage] = useState(0);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [firstPage, setFirstPage] = useState(null);
  const [lastPage, setLastPage] = useState(null);
  const [ordering, setOrdering] = useState("-network_status");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [addCashAcceptorModal, setAddCashAcceptorModal] = useState(false);
  const [editCashAcceptorModal, setEditCashAcceptorModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [payoutBoxModal, setPayoutBoxModal] = useState(false);
  const [modulesSelected, setModulesSelected] = useState([]);
  const [devices, setDevices] = useState([]);
  const [loader, setLoader] = useState(false);
  const [deviceLoader, setDeviceLoader] = useState(false);
  const [filterable, setFilterable] = useState(sensorsFields);
  const [query, setQuery] = useState("");
  const [searchQuery, setSearchQuery] = useState({});
  const [crudLoader, setCrudLoader] = useState(false);
  const [quantity, setQuantity] = useState({});
  const [payBoxDetails, setPayBoxDetails] = useState({});

  const { enqueueSnackbar } = useSnackbar();
  const [enableBills,setEnableBills]=useState(true)
  const [enableCoins,setEnableCoins]=useState(false)

  const CashAcceptorFields = [
    {
      key: "vm",
      label: "Vending Machine",
      type: editCashAcceptorModal ? "text" : "select",
      disabled: editCashAcceptorModal,
      required: true,
      visible: true,
      ...(!editCashAcceptorModal && {
        options: devices,
        multiple: true,
      }),
    },
    {
      key:"en_bills",
      label: "Enable Bills",
      description: "Only enabled bills are accepted by the Cash Acceptor.",
      type: "switch",
      visible: true,
      head:true,
      checked: enableBills
    },
    [
      {
        key: "status_1",
        label: "$ 1",
        type: "switch",
        visible: true,
        checked: addCashAcceptorModal
          ? true
          : modulesSelected[0]?.payout_box_bills["1"]?.is_enabled,
        disabled:!enableBills
      },
      {
        key: "status_2",
        label: "$ 2",
        type: "switch",
        visible: true,
        checked: addCashAcceptorModal
          ? true
          : modulesSelected[0]?.payout_box_bills["2"]?.is_enabled,
          disabled:!enableBills

      },
    ],
    [
      {
        key: "status_5",
        label: "$ 5",
        type: "switch",
        visible: true,
        checked: addCashAcceptorModal
          ? true
          : modulesSelected[0]?.payout_box_bills["5"]?.is_enabled,
          disabled:!enableBills

      },
      {
        key: "status_10",
        label: "$ 10",
        type: "switch",
        visible: true,
        checked: addCashAcceptorModal
          ? true
          : modulesSelected[0]?.payout_box_bills["10"]?.is_enabled,
          disabled:!enableBills

      },
    ],
    [
      {
        key: "status_20",
        label: "$ 20",
        type: "switch",
        visible: true,
        checked: addCashAcceptorModal
          ? true
          : modulesSelected[0]?.payout_box_bills["20"]?.is_enabled,
          disabled:!enableBills

      },
      {
        key: "status_50",
        label: "$ 50",
        type: "switch",
        visible: true,
        checked: addCashAcceptorModal
          ? true
          : modulesSelected[0]?.payout_box_bills["50"]?.is_enabled,
          disabled:!enableBills

      },
    ],
    [
      {
        key: "status_100",
        label: "$100",
        type: "switch",
        visible: true,
        checked: addCashAcceptorModal
          ? true
          : modulesSelected[0]?.payout_box_bills["100"]?.is_enabled,
          disabled:!enableBills

      },
      {},
      
    ],
    {
      key:"en_coins",
      label: "Enable Coins",
      description: "Only enabled coins are accepted by the Cash Acceptor.",
      type: "switch",
      visible: true,
      head:true
    },
    [
      {
        key: "status_1c",
        label: "1¢",
        type: "switch",
        visible: true,
        checked: addCashAcceptorModal
          ? true
          : modulesSelected[0]?.payout_box_coins["1"]?.is_enabled,
          disabled:!enableCoins
      },
      {
        key: "status_5c",
        label: "5¢",
        type: "switch",
        visible: true,
        checked: addCashAcceptorModal
          ? true
          : modulesSelected[0]?.payout_box_coins["5"]?.is_enabled,
          disabled:!enableCoins

      },
    ],
    [
      {
        key: "status_10c",
        label: "10¢",
        type: "switch",
        visible: true,
        checked: addCashAcceptorModal
          ? true
          : modulesSelected[0]?.payout_box_coins["10"]?.is_enabled,
          disabled:!enableCoins

      },
      {
        key: "status_25",
        label: "25¢",
        type: "switch",
        visible: true,
        checked: addCashAcceptorModal
          ? true
          : modulesSelected[0]?.payout_box_coins["25"]?.is_enabled,
          disabled:!enableCoins

      },
    ],
    // [
    //   {
    //     key: "status_20c",
    //     label: "20¢",
    //     type: "switch",
    //     visible: true,
    //     checked: addCashAcceptorModal
    //       ? true
    //       : modulesSelected[0]?.payout_box_coins["20"]?.is_enabled,
    //   },
    //   {
    //     key: "status_50c",
    //     label: "50¢",
    //     type: "switch",
    //     visible: true,
    //     checked: addCashAcceptorModal
    //       ? true
    //       : modulesSelected[0]?.payout_box_coins["50"]?.is_enabled,
    //   },
    // ],
    // [
    //   {
    //     key: "status_100c",
    //     label: "100¢",
    //     type: "switch",
    //     visible: true,
    //     checked: addCashAcceptorModal
    //       ? true
    //       : modulesSelected[0]?.payout_box_coins["100"]?.is_enabled,
    //   },
    //   {},
      
    // ],
    {
      label: "Payout Box Configurations for Bills",
      description:
        "Enter the preferred number of bills you wish to maintain in the Payout Box.",
      type: "label",
      visible: true,
       checked:enableCoins
    },
    [
      {
        key: "quantity_1",
        label: "$1 Bills",
        type: "number",
        visible: true,
        isInteger: true,
        max: 40,
        value: quantity?.quantity_1 || 0,
      },
      {
        key: "quantity_2",
        label: "$2 Bills",
        type: "number",
        visible: true,
        isInteger: true,
        max: 40,
        value: quantity?.quantity_2 || 0,
      },
    ],
    [
      {
        key: "quantity_5",
        label: "$5 Bills",
        type: "number",
        visible: true,
        isInteger: true,
        max: 40,
        value: quantity?.quantity_5 || 0,
      },
      {
        key: "quantity_10",
        label: "$10 Bills",
        type: "number",
        visible: true,
        isInteger: true,
        max: 40,
        value: quantity?.quantity_10 || 0,
      },
    ],
    [
      {
        key: "quantity_20",
        label: "$20 Bills",
        type: "number",
        visible: true,
        isInteger: true,
        max: 40,
        value: quantity?.quantity_20 || 0,
      },
      {
        key: "quantity_50",
        label: "$50 Bills",
        type: "number",
        visible: true,
        isInteger: true,
        max: 40,
        value: quantity?.quantity_50 || 0,
      },
    ],
    [
      {
        key: "quantity_100",
        label: "$100 Bills",
        type: "number",
        visible: true,
        isInteger: true,
        disabled: true,
        max: 40,
        value: quantity?.quantity_100 || 0,
      },
      {},
    ],
    {
      label: "Payout Box Configurations for Coins",
      description:
        "Enter the preferred number of coins you wish to maintain in the Payout Box.",
      type: "label",
      visible: true,
    },
    [
      {
        key: "quantity_1c",
        label: "1¢ coins",
        type: "number",
        visible: true,
        isInteger: true,
        max: 40,
        value: quantity?.quantity_1c || 0,
      },
      {
        key: "quantity_5c",
        label: "5¢ Coins",
        type: "number",
        visible: true,
        isInteger: true,
        max: 40,
        value: quantity?.quantity_5c || 0,
      },
    ],
    [
      {
        key: "quantity_10c",
        label: "10¢ Coins",
        type: "number",
        visible: true,
        isInteger: true,
        max: 40,
        value: quantity?.quantity_10c || 0,
      },
      {
        key: "quantity_25c",
        label: "25¢ Coins",
        type: "number",
        visible: true,
        isInteger: true,
        max: 40,
        value: quantity?.quantity_25c || 0,
      },
    ],
    // [
    //   {
    //     key: "quantity_20c",
    //     label: "20¢ Coins",
    //     type: "number",
    //     visible: true,
    //     isInteger: true,
    //     max: 40,
    //     value: quantity?.quantity_20c || 0,
    //   },
    //   {
    //     key: "quantity_50c",
    //     label: "50¢ Coins",
    //     type: "number",
    //     visible: true,
    //     isInteger: true,
    //     max: 40,
    //     value: quantity?.quantity_50c || 0,
    //   },
    // ],
    // [
    //   {
    //     key: "quantity_100c",
    //     label: "100¢ Coins",
    //     type: "number",
    //     visible: true,
    //     isInteger: true,
    //     disabled: true,
    //     max: 40,
    //     value: quantity?.quantity_100c || 0,
    //   },
    //   {},
    // ],
  ];

  const setModuleQuantity = () => {
    setQuantity({
      quantity_1:
        modulesSelected[0]?.payout_box_bills["1"]?.prefered_quantity || 0,
      quantity_2:
        modulesSelected[0]?.payout_box_bills["2"]?.prefered_quantity || 0,
      quantity_5:
        modulesSelected[0]?.payout_box_bills["5"]?.prefered_quantity || 0,
      quantity_10:
        modulesSelected[0]?.payout_box_bills["10"]?.prefered_quantity || 0,
      quantity_20:
        modulesSelected[0]?.payout_box_bills["20"]?.prefered_quantity || 0,
      quantity_50:
        modulesSelected[0]?.payout_box_bills["50"]?.prefered_quantity || 0,
      quantity_100:
        modulesSelected[0]?.payout_box_bills["100"]?.prefered_quantity || 0,
      quantity_1c:
        modulesSelected[0]?.payout_box_coins["1"]?.prefered_quantity || 0,
      quantity_2c:
        modulesSelected[0]?.payout_box_coins["2"]?.prefered_quantity || 0,
      quantity_5c:
        modulesSelected[0]?.payout_box_coins["5"]?.prefered_quantity || 0,
      quantity_10c:
        modulesSelected[0]?.payout_box_coins["10"]?.prefered_quantity || 0,
      quantity_25c:
        modulesSelected[0]?.payout_box_coins["25"]?.prefered_quantity || 0,
      quantity_50c:
        modulesSelected[0]?.payout_box_coins["50"]?.prefered_quantity || 0,
      quantity_100c:
        modulesSelected[0]?.payout_box_coins["100"]?.prefered_quantity || 0,
   
    });
  };

  const setModulePayDetails = () => {
    setPayBoxDetails({
      totalAmount: modulesSelected[0]?.payout_box_amount || 0,
      quantity_1:
        modulesSelected[0]?.payout_box_bills["1"]?.current_quantity || 0,
      quantity_2:
        modulesSelected[0]?.payout_box_bills["2"]?.current_quantity || 0,
      quantity_5:
        modulesSelected[0]?.payout_box_bills["5"]?.current_quantity || 0,
      quantity_10:
        modulesSelected[0]?.payout_box_bills["10"]?.current_quantity || 0,
      quantity_20:
        modulesSelected[0]?.payout_box_bills["20"]?.current_quantity || 0,
      quantity_50:
        modulesSelected[0]?.payout_box_bills["50"]?.current_quantity || 0,
      quantity_100:
        modulesSelected[0]?.payout_box_bills["100"]?.current_quantity || 0,
        quantity_1c:
          modulesSelected[0]?.payout_box_coins["1"]?.current_quantity || 0,
        quantity_2c:
          modulesSelected[0]?.payout_box_coins["2"]?.current_quantity || 0,
        quantity_5c:
          modulesSelected[0]?.payout_box_coins["5"]?.current_quantity || 0,
        quantity_10c:
          modulesSelected[0]?.payout_box_coins["10"]?.current_quantity || 0,
        quantity_25c:
          modulesSelected[0]?.payout_box_coins["25"]?.current_quantity || 0,
        quantity_50c:
          modulesSelected[0]?.payout_box_coins["50"]?.current_quantity || 0,
        quantity_100c:
          modulesSelected[0]?.payout_box_coins["100"]?.current_quantity || 0,
    });
  };

  const operatorFields = [
    {
      key: "id",
      columnName: "ID",
      visible: true,
    },
    {
      key: "device__serial_number",
      columnName: "Serial Number",
      visible: true,
      render: (value, rec) => rec?.device?.serial_number || "---",
    },
    {
      key: "vm_name",
      columnName: "VM Name",
      visible: true,
      render: (value, rec) => rec?.device?.vm_name || "---",
    },
    {
      key: "operator",
      columnName: "Operator",
      visible: true,
      render: (value, rec) => rec?.device?.company?.business_name || "---",
    },
    {
      key: "location",
      columnName: "Location",
      visible: true,
      render: (value, rec) => rec?.device?.location_details || "---",
    },
    {
      key: "device_is_connected",
      columnName: "Network Status",
      visible: true,
      render: (value) => <DeviceConnection status={value} />,
    },
    {
      key: "",
      columnName: "Enable Bill",
      render: (val, rec) =>
        Array.isArray(rec?.enabled_bills)
          ? rec?.enabled_bills?.map((x) => "$" + x)?.join(", ") || "---"
          : "---",
      disableSorting: true,
      visible: true,
    },
    {
      key: "is_cash_box_full",
      columnName: "Cash Box Status",
      render: (val) => (val ? "Full" : "Not Full") || "---",
      disableSorting: true,
      visible: true,
    },
    {
      key: "",
      columnName: "Payout Box Status",
      render: (_, rec) => {
        if (
          (rec?.payout_box_bills["1"]?.prefered_quantity !== 0 &&
            rec?.payout_box_bills["1"]?.current_quantity === 0) ||
          (rec?.payout_box_bills["2"]?.prefered_quantity !== 0 &&
            rec?.payout_box_bills["2"]?.current_quantity === 0) ||
          (rec?.payout_box_bills["5"]?.prefered_quantity !== 0 &&
            rec?.payout_box_bills["5"]?.current_quantity === 0) ||
          (rec?.payout_box_bills["10"]?.prefered_quantity !== 0 &&
            rec?.payout_box_bills["10"]?.current_quantity === 0) ||
          (rec?.payout_box_bills["20"]?.prefered_quantity !== 0 &&
            rec?.payout_box_bills["20"]?.current_quantity === 0) ||
          (rec?.payout_box_bills["50"]?.prefered_quantity !== 0 &&
            rec?.payout_box_bills["50"]?.current_quantity === 0)
        ) {
          let emptyBills = [];
          if (
            rec?.payout_box_bills["1"]?.prefered_quantity !== 0 &&
            rec?.payout_box_bills["1"]?.current_quantity === 0
          ) {
            emptyBills.push("$1");
          }
          if (
            rec?.payout_box_bills["2"]?.prefered_quantity !== 0 &&
            rec?.payout_box_bills["2"]?.current_quantity === 0
          ) {
            emptyBills.push("$2");
          }
          if (
            rec?.payout_box_bills["5"]?.prefered_quantity !== 0 &&
            rec?.payout_box_bills["5"]?.current_quantity === 0
          ) {
            emptyBills.push("$5");
          }
          if (
            rec?.payout_box_bills["10"]?.prefered_quantity !== 0 &&
            rec?.payout_box_bills["10"]?.current_quantity === 0
          ) {
            emptyBills.push("$10");
          }
          if (
            rec?.payout_box_bills["20"]?.prefered_quantity !== 0 &&
            rec?.payout_box_bills["20"]?.current_quantity === 0
          ) {
            emptyBills.push("$20");
          }
          if (
            rec?.payout_box_bills["50"]?.prefered_quantity !== 0 &&
            rec?.payout_box_bills["50"]?.current_quantity === 0
          ) {
            emptyBills.push("$50");
          }
          return (
            <Tooltip
              placement="top"
              size="large"
              title={
                <h6
                  style={{
                    textAlign: "center",
                    boxSizing: "border-box",
                    margin: 0
                  }}
                >{`${emptyBills.join(
                  ", "
                )} bills are empty in the Payout Box`}</h6>
              }
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <SvgIcon>
                  <svg viewBox="0 0 100 50" xmlns="http://www.w3.org/2000/svg">
                    <circle
                      cx="28"
                      cy="28"
                      r="28"
                      style={{ fill: "#DC4345" }}
                    />
                  </svg>
                </SvgIcon>
                Alert
              </div>
            </Tooltip>
          );
        } else {
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <SvgIcon>
                <svg viewBox="0 0 100 50" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="28" cy="28" r="28" style={{ fill: "#5DCF6A" }} />
                </svg>
              </SvgIcon>
              Normal
            </div>
          );
        }
      },
      disableSorting: true,
      visible: true,
    },
    {
      key: "payment_gateway",
      columnName: "Payment Gateway",
      render: (val) => val || "---",
      visible: true,
    },
  ];

  const CashFields = [
    [
      {
        leftLabel: "$ 1",
        rightLabel: `${payBoxDetails?.quantity_1} Bills`,
        type: "dataLabel",
        visible: true,
      },
      {
        leftLabel: "$ 2",
        rightLabel: `${payBoxDetails?.quantity_2} Bills`,
        type: "dataLabel",
        visible: true,
      },
    ],
    [
      {
        leftLabel: "$ 5",
        rightLabel: `${payBoxDetails?.quantity_5} Bills`,
        type: "dataLabel",
        visible: true,
      },
      {
        leftLabel: "$ 10",
        rightLabel: `${payBoxDetails?.quantity_10} Bills`,
        type: "dataLabel",
        visible: true,
      },
    ],
    [
      {
        leftLabel: "$ 20",
        rightLabel: `${payBoxDetails?.quantity_20} Bills`,
        type: "dataLabel",
        visible: true,
      },
      {
        leftLabel: "$ 50",
        rightLabel: `${payBoxDetails?.quantity_50} Bills`,
        type: "dataLabel",
        visible: true,
      },
    ],
    [
      {
        leftLabel: "$100",
        rightLabel: `${payBoxDetails?.quantity_100} Bills`,
        type: "dataLabel",
        visible: true,
      },
      {},
    ],
    [
      {
        leftLabel: "1 ¢",
        rightLabel: `${payBoxDetails?.quantity_1c} Coins`,
        type: "dataLabel",
        visible: true,
      },
      {
        leftLabel: "5 ¢",
        rightLabel: `${payBoxDetails?.quantity_5c} Coins`,
        type: "dataLabel",
        visible: true,
      },
    ],
    [
      {
        leftLabel: "10 ¢",
        rightLabel: `${payBoxDetails?.quantity_10c} Coins`,
        type: "dataLabel",
        visible: true,
      },
      {
        leftLabel: "25 ¢",
        rightLabel: `${payBoxDetails?.quantity_25c} Coins`,
        type: "dataLabel",
        visible: true,
      },
    ],
    
  ];

  const setup = () => {
    setLoader(true);
    setModulesList([]);
    setModulesSelected([]);
  };

  const handleRes = (data) => {
    setModulesList(data?.results);
    setNextPage(data?.next);
    setPreviousPage(data?.previous);
    setFirstPage(data?.first);
    setLastPage(data?.last);
    setLoader(false);
    setDataCount(data?.count);
  };

  const handleFilter = (arr) => {
    setFilterable(arr);
    if (query !== "") {
      let searchFilter = handleMultiFilterSearch(filterLabels, arr, query);
      if (searchFilter["is_virtual"] !== undefined) {
        if (query.toLowerCase() === "yes") {
          searchFilter["is_virtual"] = "true";
        } else if (query.toLowerCase() === "no") {
          searchFilter["is_virtual"] = "false";
        }
      }

      setup();
      setPage(0);
      CashService.acceptorDetails({
        ...searchFilter,
        limit: rowsPerPage,
        ordering: ordering,
      })
        .then((data) => {
          handleRes(data);
        })
        .catch((err) => {
          setLoader(false);
        });
    }
  };
  const fetchDevices = () => {
    setDeviceLoader(true);
    window
      .axiosIns("device", {
        params: { ordering: "vm_name", all: "true", no_cash_acceptor: "true" },
      })
      .then(({ data }) => {
        setDevices(
          data?.data?.results.map((x) => ({
            label: `${x.vm_name ?? ""} || ${x.serial_number}`,
            value: x.id,
          }))
        );
        setDeviceLoader(false);
      })
      .catch(() => {
        setDeviceLoader(false);
      });
  };

  useEffect(() => {
    getModules(undefined, undefined, 0);
    fetchDevices();
  }, []);

  const handleSearch = (value) => {
    setQuery(value);
    let searchFilter = {};
    if (value !== "") {
      searchFilter = handleMultiFilterSearch(filterLabels, filterable, value);
      if (searchFilter["is_virtual"] !== undefined) {
        if (value.toLowerCase() === "yes") {
          searchFilter["is_virtual"] = "true";
        } else if (value.toLowerCase() === "no") {
          searchFilter["is_virtual"] = "false";
        }
      }
    }
    setSearchQuery(searchFilter);
    setup();
    setPage(0);
    CashService.acceptorDetails({
      ...searchFilter,
      limit: rowsPerPage,
      ordering: ordering,
    })
      .then((data) => {
        handleRes(data);
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  const getModules = (order, max, customPage = page) => {
    const params = {
      ...searchQuery,
      limit: max ? max : rowsPerPage,
      ordering: order ? order : ordering,
      page: customPage + 1,
    };

    setup();
    CashService.acceptorDetails(params)
      .then((data = {}) => {
        handleRes(data);
      })
      .catch((err) => {
        setLoader(false);
        if (err?.detail) {
          enqueueSnackbar(err?.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not get devices. Try again."
          );
        }
      });
  };

  const handleAdd = (values) => {
    if (
      Number(values?.quantity_1) +
        Number(values?.quantity_2) +
        Number(values?.quantity_5) +
        Number(values?.quantity_10) +
        Number(values?.quantity_20) +
        Number(values?.quantity_50) >
      40
    ) {
      enqueueSnackbar(
        "Quantity of all Payout Box bills must be less than or equal to 40 bills"
      );
      return;
    }
    let data = {
      device: values?.vm,
      bills: {
        1: {
          prefered_quantity: values?.quantity_1
            ? Number(values?.quantity_1)
            : 0,
          is_enabled: enableBills?values?.status_1 === undefined ? true : values?.status_1:false,
        },
        2: {
          prefered_quantity: values?.quantity_2
            ? Number(values?.quantity_2)
            : 0,
          is_enabled: enableBills?values?.status_2 === undefined ? true : values?.status_2:false,
        },
        5: {
          prefered_quantity: values?.quantity_5
            ? Number(values?.quantity_5)
            : 0,
          is_enabled:enableBills?values?.status_5 === undefined ? true : values?.status_5:false,
        },
        10: {
          prefered_quantity: values?.quantity_10
            ? Number(values?.quantity_10)
            : 0,
          is_enabled:enableBills?
            values?.status_10 === undefined ? true : values?.status_10:false,
        },
        20: {
          prefered_quantity: values?.quantity_20
            ? Number(values?.quantity_20)
            : 0,
          is_enabled:enableBills?
            values?.status_20 === undefined ? true : values?.status_20:false,
        },
        50: {
          prefered_quantity: values?.quantity_50
            ? Number(values?.quantity_50)
            : 0,
          is_enabled:enableBills?
            values?.status_50 === undefined ? true : values?.status_50:false,
        },
        100: {
          prefered_quantity: values?.quantity_100
            ? Number(values?.quantity_100)
            : 0,
          is_enabled:enableBills?
            values?.status_100 === undefined ? true : values?.status_100:false,
        },
      },
      coins: {
        1: {
          prefered_quantity: values?.quantity_1c
            ? Number(values?.quantity_1c)
            : 0,
          is_enabled: enableCoins?values?.status_1c === undefined ? true : values?.status_1c:false,
        },
      
        5: {
          prefered_quantity: values?.quantity_5c
            ? Number(values?.quantity_5c)
            : 0,
          is_enabled: enableCoins?values?.status_5c === undefined ? true : values?.status_5c:false,
        },
        10: {
          prefered_quantity: values?.quantity_10c
            ? Number(values?.quantity_10c)
            : 0,
          is_enabled:enableCoins?
            values?.status_10c === undefined ? true : values?.status_10c:false,
        },
      
        25: {
          prefered_quantity: values?.quantity_25c
            ? Number(values?.quantity_25c)
            : 0,
          is_enabled:enableCoins?
            values?.status_25c === undefined ? true : values?.status_25c:false,
        },
      
      },
    };
    setCrudLoader(true);
    CashService.addConfiguration(data)
      .then(() => {
        enqueueSnackbar("Cash Acceptor configuration added successfully");
        setAddCashAcceptorModal(false);
        setModulesSelected([]);
        getModules();
        fetchDevices();
      })
      .catch((err) => {
        if (err?.response?.data?.detail) {
          enqueueSnackbar(err?.response?.data?.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not add configuration. Try again."
          );
        }
      })
      .finally(() => {
        setCrudLoader(false);
      });
  };
  const handleEdit = (values, modulesSelected) => {
    if (
      Number(values?.quantity_1) +
        Number(values?.quantity_2) +
        Number(values?.quantity_5) +
        Number(values?.quantity_10) +
        Number(values?.quantity_20) +
        Number(values?.quantity_50) >
      40
    ) {
      enqueueSnackbar(
        "Quantity of all Payout Box bills must be less than or equal to 40 bills"
      );
      return;
    }
    let data = {
      device: modulesSelected[0]?.device?.id,
      bills: {
        1: {
          prefered_quantity: values?.quantity_1
            ? Number(values?.quantity_1)
            : 0,
          is_enabled:enableBills?
            values?.status_1 === undefined
              ? modulesSelected[0]?.payout_box_bills["1"]?.is_enabled || false
              : values?.status_1:false,
        },
        2: {
          prefered_quantity: values?.quantity_2
            ? Number(values?.quantity_2)
            : 0,
          is_enabled:enableBills?
            values?.status_2 === undefined
              ? modulesSelected[0]?.payout_box_bills["2"]?.is_enabled || false
              : values?.status_2:false,
        },
        5: {
          prefered_quantity: values?.quantity_5
            ? Number(values?.quantity_5)
            : 0,
          is_enabled:enableBills?
            values?.status_5 === undefined
              ? modulesSelected[0]?.payout_box_bills["5"]?.is_enabled || false
              : values?.status_5:false,
        },
        10: {
          prefered_quantity: values?.quantity_10
            ? Number(values?.quantity_10)
            : 0,
          is_enabled:enableBills?
            values?.status_10 === undefined
              ? modulesSelected[0]?.payout_box_bills["10"]?.is_enabled || false
              : values?.status_10:false,
        },
        20: {
          prefered_quantity: values?.quantity_20
            ? Number(values?.quantity_20)
            : 0,
          is_enabled:enableBills?
            values?.status_20 === undefined
              ? modulesSelected[0]?.payout_box_bills["20"]?.is_enabled || false
              : values?.status_20:false,
        },
        50: {
          prefered_quantity: values?.quantity_50
            ? Number(values?.quantity_50)
            : 0,
          is_enabled:enableBills?
            values?.status_50 === undefined
              ? modulesSelected[0]?.payout_box_bills["50"]?.is_enabled || false
              : false:false,
        },
        100: {
          prefered_quantity: values?.quantity_100
            ? Number(values?.quantity_100)
            : 0,
          is_enabled:enableBills?
            values?.status_100 === undefined
              ? modulesSelected[0]?.payout_box_bills["100"]?.is_enabled || false
              : values?.status_100:false,
        },
      },
      coins: {
        1: {
          prefered_quantity: values?.quantity_1c
            ? Number(values?.quantity_1c)
            : 0,
          is_enabled:enableCoins?
            values?.status_1c === undefined
              ? modulesSelected[0]?.payout_box_coins["1"]?.is_enabled || false
              : values?.status_1c:false,
        },
        // 2: {
        //   prefered_quantity: values?.quantity_2c
        //     ? Number(values?.quantity_2c)
        //     : 0,
        //   is_enabled:enableCoins?
        //     values?.status_2c === undefined
        //       ? modulesSelected[0]?.payout_box_coins["2"]?.is_enabled || false
        //       : values?.status_2c:false,
        // },
        5: {
          prefered_quantity: values?.quantity_5c
            ? Number(values?.quantity_5c)
            : 0,
          is_enabled:enableCoins?
            values?.status_5c === undefined
              ? modulesSelected[0]?.payout_box_coins["5"]?.is_enabled || false
              : values?.status_5c:false,
        },
        10: {
          prefered_quantity: values?.quantity_10c
            ? Number(values?.quantity_10c)
            : 0,
          is_enabled:enableCoins?
            values?.status_10c === undefined
              ? modulesSelected[0]?.payout_box_coins["10"]?.is_enabled || false
              : values?.status_10c:false,
        },
        // 20: {
        //   prefered_quantity: values?.quantity_20c
        //     ? Number(values?.quantity_20c)
        //     : 0,
        //   is_enabled:enableCoins?
        //     values?.status_20c === undefined
        //       ? modulesSelected[0]?.payout_box_coins["20"]?.is_enabled || false
        //       : values?.status_20c:false,
        // },
        25: {
          prefered_quantity: values?.quantity_25c
            ? Number(values?.quantity_25c)
            : 0,
          is_enabled:enableCoins?
            values?.status_25c === undefined
              ? modulesSelected[0]?.payout_box_coins["25"]?.is_enabled || false
              : values?.status_25c:false,
        },
        // 50: {
        //   prefered_quantity: values?.quantity_50c
        //     ? Number(values?.quantity_50c)
        //     : 0,
        //   is_enabled:enableCoins?
        //     values?.status_50c === undefined
        //       ? modulesSelected[0]?.payout_box_coins["50"]?.is_enabled || false
        //       : false:false,
        // },
        // 100: {
        //   prefered_quantity: values?.quantity_100c
        //     ? Number(values?.quantity_100c)
        //     : 0,
        //   is_enabled:enableCoins?
        //     values?.status_100c === undefined
        //       ? modulesSelected[0]?.payout_box_coins["100"]?.is_enabled || false
        //       : values?.status_100c:false,
        // },
      },
    };
    setCrudLoader(true);
    CashService.editConfiguration(modulesSelected[0]?.id, data)
      .then(() => {
        enqueueSnackbar("Cash Acceptor configuration updated successfully");
        setEditCashAcceptorModal(false);
        setModulesSelected([]);
        getModules();
        fetchDevices();
      })
      .catch((err) => {
        if (err?.response?.data?.detail) {
          enqueueSnackbar(err?.response?.data?.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not edit configuration. Try again."
          );
        }
      })
      .finally(() => {
        setCrudLoader(false);
      });
  };

  const changePage = (url) => {
    setup();
    window.axiosIns
      .get(url)
      .then((data = {}) => {
        handleRes(data?.data);
      })
      .catch((err) => {
        setLoader(false);
        if (err?.detail) {
          enqueueSnackbar(err?.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not get devices. Try again."
          );
        }
      });
  };

  const handleDelete = () => {
    setCrudLoader(true);
    modulesSelected.forEach((val, index) => {
      CashService.deleteConfiguration(val?.id)
        .then(() => {
          setDeleteModal(false);
          if (modulesSelected?.length === index + 1) {
            setCrudLoader(false);
            enqueueSnackbar("Cash acceptor configuration(s) deleted successfully.");
            if (
              modulesList?.length - modulesSelected?.length === 0 &&
              page > 0
            ) {
              setPage(page - 1);
              changePage(previousPage);
            } else {
              getModules();
              fetchDevices();
            }
          }
        })
        .catch((err) => {
          setCrudLoader(false);
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not delete Configuration(s). Try again."
          );
        });
    });
  };

  return (
    <div id="sa-modules-wrapper" className={classes.wrapper}>
      <div className={classes.contentHeader}>
        <Typography
          style={{ fontSize: "0.9em", marginRight: 20 }}
          variant="body2"
          color="textSecondary"
        >
          All Vending Machines with Cash Recycler are shown below. Cash bill
          settings can be viewed and edited from here.
        </Typography>
      </div>
      <div
        style={{
          marginTop: 10,
          display: "flex",
          justifyContent: "space-between",
          paddingLeft: "1.5rem",
          paddingRight: "1.5rem",
        }}
      >
        <div item xs={12} sm={6} md={2}>
          <AddButton
            className="mr-3"
            label="Add"
            onClick={() => {
              setQuantity(intialAddState);
              setAddCashAcceptorModal(true);
              setEnableBills(true);
              setEnableCoins(false);
            }}
          />
          <EditButton
            disabled={modulesSelected?.length !== 1}
            className="mr-3"
            label="Edit"
            onClick={() => {
              setModuleQuantity();
              setEditCashAcceptorModal(true);
            }}
          />
          <DeleteButton
            disabled={modulesSelected?.length === 0}
            className="mr-3"
            label="Delete"
            onClick={() => setDeleteModal(true)}
          />
          <ButtonIcon
            disabled={modulesSelected?.length !== 1}
            className="mr-3"
            label="Payout Box Details"
            onClick={() => {
              setModulePayDetails();
              setPayoutBoxModal(true);
            }}
            Icon={MoveToInbox}
          />
          <RefreshButton
            className="mr-3"
            label="Refresh"
            onClick={() => {
              getModules(undefined, undefined, 0);
              fetchDevices();
            }}
          />
        </div>
        <div>
          <SearchBox
            width="100%"
            multiple={true}
            query={query}
            onChange={handleFilter}
            fields={sensorsFields}
            selectedFields={filterable}
            handleSearch={handleSearch}
          />
        </div>
      </div>
      <div className={classes.content}>
        <TableGenerator
          searchQuery={query}
          initialSort={"-id"}
          searchColumnsFilter={true}
          fields={operatorFields}
          data={!loader && !deviceLoader? modulesList : []}
          loader={loader || deviceLoader}
          currentPage={page}
          handleSortChange={(ordering) => {
            setOrdering(ordering);
            getModules(ordering);
          }}
          onPageChange={(page, direction) => {
            setPage(page);
            if (direction === "next") {
              changePage(nextPage);
            } else if (direction === "back") {
              changePage(previousPage);
            } else if (direction === "first") {
              changePage(firstPage);
            } else if (direction === "last") {
              changePage(lastPage);
            }
          }}
          onRowPerPageChange={(rows) => {
            getModules(null, rows, 0);
            setRowsPerPage(rows);
            setPage(0);
          }}
          backendPagination={true}
          dataCount={dataCount}
          onChangePage={(page) => console.log(page)}
          selectedRecords={modulesSelected}
          rowOnePage={10}
          onChangeSelected={(modulesSelected) =>
            setModulesSelected(modulesSelected)
          }
        />
        <CrudDialog
          title="Add Cash Acceptor Configurations"
          okText="Add"
          fields={CashAcceptorFields}
          description="Please fill in the details below."
          crudLoader={crudLoader}
          onSubmit={(values) => {
            handleAdd(values);
          }}
          open={addCashAcceptorModal}
          onClose={() => {
            setAddCashAcceptorModal(false);
            setEnableBills(true);
            setEnableCoins(false);
          }}
          onFieldChange={(field, val) => {
            if(field?.key==="en_bills"){
              setEnableBills(val)
            }
            if(field?.key==="en_coins"){
              setEnableCoins(val)
            }
            if (field?.key.startsWith("quantity")) {
              let quan = {};
              quan[field?.key] = val;
              setQuantity({ ...quantity, ...quan });
            }
          }}
          showSubmitActionLoader={crudLoader}
        />
        <CrudDialog
          title="Edit Cash Acceptor Configurations"
          okText="Save"
          fields={CashAcceptorFields}
          description="Please fill in the details below."
          crudLoader={crudLoader}
          values={{
            ...modulesSelected[0],
            vm: editCashAcceptorModal ?
              modulesSelected[0]?.device?.vm_name +
              " || " +
              modulesSelected[0]?.device?.serial_number : [],
          }}
          onSubmit={(values) => {
            handleEdit(values, modulesSelected);
          }}
          open={editCashAcceptorModal}
          onClose={() => {
            setEditCashAcceptorModal(false);
            setEnableBills(true);
            setEnableCoins(false);
          }}
          onFieldChange={(field, val) => {
            if(field?.key==="en_bills"){
              setEnableBills(val)
            }
            if(field?.key==="en_coins"){
              setEnableCoins(val)
            }
            if (field?.key.startsWith("quantity")) {
              let quan = {};
              quan[field?.key] = val;
              setQuantity({ ...quantity, ...quan });
            }
          }}
          showSubmitActionLoader={crudLoader}
        />
        <CrudDialog
          title="Payout Box Details"
          fields={CashFields}
          okText={false}
          description="Bills and Coins present in the Payout Box are displayed below."
          open={payoutBoxModal}
          cancelText="Close"
          onClose={() => {
            setPayoutBoxModal(false);
            setTimeout(()=>{setPayBoxDetails(intialBoxState)},500)
          }}
        />
        <CrudDialog
          title="Delete Configuration(s)"
          description="Are you sure you want to delete the Configuration(s)?"
          okText="Delete"
          onSubmit={() => handleDelete()}
          open={deleteModal}
          onClose={() => {
            setDeleteModal(false);
            setCrudLoader(false);
          }}
          showSubmitActionLoader={crudLoader}
        />
      </div>
    </div>
  );
};

export default withTheme(CashAcceptorApp);
