import React, {useState, useEffect} from 'react'
import { withStyles } from "@material-ui/core/styles";
import { useHistory } from 'react-router';
import { Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";
import styles from "./../Variant/styles";
import './../Variant/styles.css'
import { AddButton, EditButton } from "../common/Buttons";
import ContentHeader from "../common/ContentHeader";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useSnackbar } from "notistack";
import {
    handleServerErrors,
  } from "../../utiles/helpers";
import { enableProductDropDown } from 'redux/actions';

import {Loader} from './../../utiles/helpers'

const Settings = ({ setActiveTab }) => {
    const classes = styles();
    const [productCheck, setProductCheck] = useState(false);
    const [barCheck, setBarCheck] = useState(true);
    const [infoCheck, setInfoCheck] = useState(false);
    const [ingredientsCheck, setIngredientsCheck] = useState(false);
    const [settingsData, setSettingsData] = useState(false);
    const [loader, setLoader] = useState(true);
    const [recommendedProducts, setRecommendedProducts] = useState(false);
    const [productSorting, setProductSorting] = useState(false);
    const [productPopularity, setProductPopularity] = useState(false);
    const [productTimeRestriction, setProductTimeRestriction] = useState(false);
    const [enableTax, setEnableTax] = useState(false);
    const [barcodeToDescription, setBarcodeToDescription] = useState(false);
    const [enableEmergencyProduct, setEnableEmergencyProduct] = useState(false);
    const [enableProductInstructionalVideo,setEnableProductInstructionalVideo] = useState(false);
    const [enableProductLanguage,setEnableProductLanguage] = useState(false);
    // 
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();

    useEffect(() => {
        getSubCategory();
      }, []);


    const handleCheckboxChange = (event, setState) => {
        setState(event.target.checked);
    };

    const getSubCategory = async () => {
        setLoader(true);
        window
          .axiosIns("/products_settings")
          .then(({ data = {} }) => {
            setSettingsData(data.results?.[0])
            setProductCheck(data.results?.[0]?.['enable_subcategory']);
            setBarCheck(data.results?.[0]?.['enable_barcode'] ?? true);
            setInfoCheck(data.results?.[0]?.['enable_info_img'] ?? false);
            setIngredientsCheck(data.results?.[0]?.['enable_ingredient_img'] ?? false);
            setRecommendedProducts(data.results?.[0]?.['enable_recommend_product'] ?? false);
            setProductSorting(data.results?.[0]?.['enable_planogram_product_sorting'] ?? false);
            setProductPopularity(data.results?.[0]?.['enable_product_popularity'] ?? false);
            setProductTimeRestriction(data.results?.[0]?.['enable_product_selling_time'] ?? false);
            setEnableTax(data.results?.[0]?.['enable_taxes_per_product'] ?? false);
            setBarcodeToDescription(data.results?.[0]?.['change_barcode_to_desc_img'] ?? false);
            setEnableEmergencyProduct(data.results?.[0]?.['enable_emergency_product_flag'] ?? false);
            setEnableProductInstructionalVideo(data.results?.[0]?.['enable_video_description'] ?? false);
            setEnableProductLanguage(data.results?.[0]?.['enable_product_language'] ?? false); 
            dispatch(enableProductDropDown(data.results?.[0]?.['enable_product_language']))
            setLoader(false);
          })
          .catch((err) => {
            if (err.detail) {
              enqueueSnackbar(err.detail);
            } else {
              handleServerErrors(
                err,
                enqueueSnackbar,
                "Could not get data. Try again."
              );
            }
            setLoader(false);
          });
      };


    const handleAdd = () => {
        const fd = new FormData();
    
        fd.append("enable_subcategory", productCheck);
        fd.append("enable_barcode", barCheck ?? true);
        fd.append("enable_info_img", infoCheck ?? false);
        fd.append("enable_ingredient_img", ingredientsCheck ?? false);
        fd.append("enable_recommend_product", recommendedProducts ?? false);
        fd.append("enable_planogram_product_sorting", productSorting ?? false);
        fd.append("enable_product_popularity", productPopularity ?? false);
        fd.append("enable_product_selling_time", productTimeRestriction ?? false);
        fd.append("enable_taxes_per_product", enableTax ?? false);
        fd.append("change_barcode_to_desc_img", barcodeToDescription ?? false);
        fd.append("enable_emergency_product_flag", enableEmergencyProduct ?? false);
        fd.append("enable_video_description", enableProductInstructionalVideo ?? false);
        fd.append("enable_product_language", enableProductLanguage ?? false);
        // 

        setLoader(true);

        settingsData && settingsData['id'] ?
        
        window.axiosIns
          .put(`/products_settings/${settingsData['id']}`, fd)
          .then(() => {
            enqueueSnackbar("Settings updated successfully.");
          })
          .catch((err) => {
            if (err.response.data.detail) {
              enqueueSnackbar(err.response.data.detail);
            } else {
              handleServerErrors(
                err,
                enqueueSnackbar,
                "Could not save settings. Try again."
              );
            }
          })
          .then(() => {
            setSettingsData(false);
            getSubCategory();
          })

        :
    
        window.axiosIns
          .post(`/products_settings`, fd)
          .then(() => {
            enqueueSnackbar("Settings updated successfully.");
          })
          .catch((err) => {
            if (err.response.data.detail) {
              enqueueSnackbar(err.response.data.detail);
            } else {
              handleServerErrors(
                err,
                enqueueSnackbar,
                "Could not save settings. Try again."
              );
            }
          })
          .then(() => {
            setSettingsData(false);
            getSubCategory();
          });
      };



 return (
    <div id="sa-modules-wrapper" className={classes.wrapper}>
      <ContentHeader
        title="Settings"
        description="
        Settings can be used to take advantage of product features like enabling sub-categories etc."
      />
      <div className={classes.toolbar}>
        <div className={classes.crudButtons}>
          <AddButton
            className="mr-3"
            label="Save"
            save="true"
            disabled = { settingsData['id'] && settingsData['enable_subcategory'] === productCheck &&
                          settingsData["enable_barcode"] === barCheck &&
                          settingsData["enable_info_img"] === infoCheck &&
                          settingsData["enable_ingredient_img"] === ingredientsCheck &&
                          settingsData["enable_recommend_product"] === recommendedProducts &&
                          settingsData["enable_planogram_product_sorting"] === productSorting &&
                          settingsData["enable_product_popularity"] === productPopularity &&
                          settingsData["enable_product_selling_time"] === productTimeRestriction &&
                          settingsData["enable_taxes_per_product"] === enableTax &&
                          settingsData["change_barcode_to_desc_img"] === barcodeToDescription &&
                          settingsData["enable_emergency_product_flag"] === enableEmergencyProduct &&
                          settingsData["enable_video_description"] === enableProductInstructionalVideo &&
                          settingsData["enable_product_language"] === enableProductLanguage
                        }
            onClick={() => handleAdd()}
          />
          <EditButton
            // disabled={modulesSelected.length !== 1 || current_user.type === 'SU'}
            className="mr-3"
            label="Cancel"
            cancel="true"
            onClick={() => {
              setActiveTab('product')
              window.history.replaceState(null, null, "?tab=product")
            }}
          />
        </div>
      </div>

      <div className={classes.settings}>
        <div className={classes.settings__product_features}>
            <Typography
                variant="title"
            >
                Products Features
            </Typography>
        </div>
        {
      
          loader ? <Loader visible={loader} /> : 
          <div style={{paddingBottom:"40px"}}>

            <div className={classes.settings__subcategory_checkbox}>
              <FormControlLabel
                  control={<Checkbox checked={productCheck} color="primary" onChange={(e) => handleCheckboxChange(e, setProductCheck)} />}
                  label={
                    <span>
                        Enable Subcategories    <span style={{ opacity: 0.5 }}>(Unavailable on Generic Software)</span>
                    </span>
                }
              />
            </div>
            <div className={classes.settings__subcategory_checkbox}>
              <FormControlLabel
                  control={<Checkbox checked={barCheck} color="primary" onChange={(e) => handleCheckboxChange(e, setBarCheck)} />}
                  label={
                    <span>
                      Enable Barcode   <span style={{ opacity: 0.5 }}>(Unavailable on Generic Software)</span>
                    </span>
                    }
              />
              <div style={{ marginLeft: 20 }}>
                <FormControlLabel
                  control={<Checkbox checked={barcodeToDescription} color="primary" onChange={(e) => handleCheckboxChange(e, setBarcodeToDescription)} />}
                  label={
                  <span>
                    Change Barcode to Description Image  <span style={{ opacity: 0.5 }}>(Unavailable on Generic Software)</span>
                  </span>
                  }
                  disabled={!barCheck}
                />
              </div>
            </div>
            <div className={classes.settings__subcategory_checkbox}>
              <FormControlLabel
                  control={<Checkbox checked={infoCheck} color="primary" onChange={(e) => handleCheckboxChange(e, setInfoCheck)} />}
                  label={
                    <span>
                      Enable more info images   <span style={{ opacity: 0.5 }}>(Unavailable on Generic Software)</span>
                    </span>
                    }
                  
              />
            </div>
            <div className={classes.settings__subcategory_checkbox}>
              <FormControlLabel
                  control={<Checkbox checked={ingredientsCheck} color="primary" onChange={(e) => handleCheckboxChange(e, setIngredientsCheck)} />}
                  label={
                    <span>
                     Enable ingredients image  <span style={{ opacity: 0.5 }}>(Unavailable on Generic Software)</span>
                    </span>
                    }
                  
              />
            </div>
            <div className={classes.settings__subcategory_checkbox}>
              <FormControlLabel
                  control={<Checkbox checked={recommendedProducts} color="primary" onChange={(e) => handleCheckboxChange(e, setRecommendedProducts)} />}
                  
                  label={
                    <span>
                     Enable Recommended Products    <span style={{ opacity: 0.5 }}>(Unavailable on Generic Software)</span>
                    </span>
                    }
                  
              />
            </div>
            <div className={classes.settings__subcategory_checkbox}>
              <FormControlLabel
                  control={<Checkbox checked={productSorting} color="primary" onChange={(e) => handleCheckboxChange(e, setProductSorting)} />}
                  label="Enable Product Ordering "
              />
            </div>
            <div className={classes.settings__subcategory_checkbox}>
              <FormControlLabel
                  control={<Checkbox checked={productPopularity} color="primary" onChange={(e) => handleCheckboxChange(e, setProductPopularity)} />}
                  label={
                    <span>
                      Enable Product Popularity    <span style={{ opacity: 0.5 }}>(Unavailable on Generic Software)</span>
                    </span>
                    }
                  
                  
              />
            </div>
            <div className={classes.settings__subcategory_checkbox}>
              <FormControlLabel
                  control={<Checkbox checked={productTimeRestriction} color="primary" onChange={(e) => handleCheckboxChange(e, setProductTimeRestriction)} />}
                  label="Enable Product Restriction based on Time" 
              />
            </div>
            <div className={classes.settings__subcategory_checkbox}>
              <FormControlLabel
                  control={<Checkbox checked={enableTax} color="primary" onChange={(e) => handleCheckboxChange(e, setEnableTax)} />}
                  label="Enable Tax on Product and Variant"
              />
            </div>
            <div className={classes.settings__subcategory_checkbox}>
              <FormControlLabel
                  control={<Checkbox checked={enableEmergencyProduct} color="primary" onChange={(e) => handleCheckboxChange(e, setEnableEmergencyProduct)} />}
                  label={
                    <span>
                      Enable Emergency Product Dispensing   <span style={{ opacity: 0.5 }}>(Unavailable on Generic Software)</span>
                    </span>
                    }
               
                  
              />
            </div>
            <div className={classes.settings__subcategory_checkbox}>
              <FormControlLabel
                  control={<Checkbox checked={enableProductInstructionalVideo} color="primary" onChange={(e) => handleCheckboxChange(e, setEnableProductInstructionalVideo)} />}
      
                  label={
                    <span>
                      Enable Product Instructions Video   <span style={{ opacity: 0.5 }}>(Unavailable on Generic Software)</span>
                    </span>
                    }
                  
              />
            </div>
            <div className={classes.settings__subcategory_checkbox}>
              <FormControlLabel
                  control={<Checkbox checked={enableProductLanguage} color="primary" onChange={(e) => handleCheckboxChange(e, setEnableProductLanguage)} />}
                  label={
                    <span>
                      Enable Product Language <span style={{ opacity: 0.5 }}>(Unavailable on Generic Software)</span>
                    </span>
                    } 
              />
            </div>
          </div>
        }

      </div>      

    </div>
 )
}

export default withStyles({}, { withTheme: true })(Settings);
