import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { Typography, withStyles, CircularProgress, Box } from "@material-ui/core";
import { Link } from "react-router-dom";
import _flatten from "lodash/flatten";
import HelpIcon from "@material-ui/icons/Help";
import styles from "./styles";

import { FieldsLayout, File_with_preview, SwitchSelect_skip } from "./fields";
import pic from "../../../../assets/images/1.png";
import pic1 from "../../../../assets/images/2.png";
import pic2 from "../../../../assets/images/3.png";
import pic3 from "../../../../assets/images/4.png";
import pic4 from "../../../../assets/images/5.png";
import pic5 from "../../../../assets/images/6.png";


import LabelledOutline from "./fields/LabelledOutline";
import {
  Text,
  RadioSelect,
  SwitchSelect
} from "./fields";

import { Loader } from "components/Common";

class CrudDialog extends Component {
  state = {
    fieldValues: {},
    fieldErrors: {},
    selectedLayout: "default",
  };

  componentDidMount() {
    this.setState({ fieldValues: this.parseValues(this.props) });
    this.props.onRef(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.open == false && nextProps.open) {
      this.setState({ fieldValues: this.parseValues(nextProps) });
    }
  }

  parseValues(props) {
    const values = {};
    _flatten(props.fields).forEach((field) => {
      if (field.form === false) {
        return;
      }
      let _value = props.values[field.key];
      if (field.type === "select") {
        if (!field.multiple) {
          _value =
            typeof _value === "object" && _value !== null ? _value.id : _value;
        }
      }
      values[field.key] = _value || field.value;
    });

    return values;
  }

  getLayoutSize(layout) {
    const layoutConfig = {
      'default': '56px * 97px',
      'Layout 12': '56px * 97px', // Example sizes, adjust as needed
      'Layout 9': '76px * 97px',
      'Layout 1': '76px * 137px',
      'Layout 2': '113px * 137px',
      'Layout 3': '113px * 272px',
    };
    return layoutConfig[layout] || 'Unknown size';
  }

  handleFieldChange(field, value) {
    const fieldErrors = this.state.fieldErrors;
    const fieldValues = this.state.fieldValues;
    fieldErrors[field.key] = undefined;
    
    if (field.key === "is_category_screen_default") {
        if (value === true) {
            fieldValues["show_category_dropdown"] = true;
            fieldValues["enable_category_search"] = true;
            fieldValues["enable_language_dropdown"]= false;
        } else {
            fieldValues["enable_category_search"] = false;
        }
    } else {
        if (!fieldValues["is_category_screen_default"] && 
            (field.key === "enable_category_search" || field.key === "show_category_dropdown")) {
            const otherKey = field.key === "enable_category_search" ? "show_category_dropdown" : "enable_category_search";
            if (value === true) {
                fieldValues[otherKey] = false;
            }
        }
    }
    if (field.key === "show_category_dropdown"){
      fieldValues["enable_language_dropdown"]= false;
    }
    if (field.key === "enable_category_search"){
      fieldValues["enable_language_dropdown"]= false;
    }
  
    if (field.key === "layout") {
      this.setState({ selectedLayout: value });
    }
    if (field.key === "enable_language_dropdown") {
      if (value === true) {
          fieldValues["show_category_dropdown"] = false;
          fieldValues["enable_category_search"] = false;
          fieldValues["is_category_screen_default"] = false; 
      } else if (value === false){
        fieldValues["show_category_dropdown"] = true;
      }
  }

    fieldValues[field.key] = field.key === "is_open" ? !!value : value;
    this.setState({ fieldErrors, fieldValues });
    this.props.onFieldChange(field, value);
}


  validate(field) {
    const validations = field.validations;
    const fieldValue =
      this.state.fieldValues[field.key] &&
      this.state.fieldValues[field.key].toString();
    const fieldErrors = this.state.fieldErrors;
    if (validations && Array.isArray(validations) && validations.length) {
      validations.forEach((rule) => {
        if (
          rule.type === "required" &&
          rule.value === true &&
          (!fieldValue || !fieldValue.trim())
        ) {
          fieldErrors[field.key] = rule.message || "Required";
        } else if (
          rule.type === "maxLength" &&
          rule.value &&
          (fieldValue || "").toString().length > rule.value
        ) {
          fieldErrors[field.key] = rule.message || `Max length ${rule.value}`;
        } else if (
          rule.type === "minLength" &&
          rule.value &&
          (fieldValue || "").toString().length < rule.value
        ) {
          fieldErrors[field.key] = rule.message || `Min length ${rule.value}`;
        } else if (
          rule.type === "pattern" &&
          rule.value &&
          !(fieldValue || "")
            .toString()
            .match(new RegExp(`\\b${rule.value}\\b`, "g"))
        ) {
          fieldErrors[field.key] = rule.message || `Invalid value`;
        } else if (
          rule.type === "length" &&
          rule.value &&
          (fieldValue || "").toString().length !== rule.value
        ) {
          fieldErrors[field.key] =
            rule.message || `Length must be ${rule.value}`;
        } else if (
          rule.type === "custom" &&
          rule.value &&
          typeof rule.value === "function" &&
          !rule.value(fieldValue, this.state.fieldValues)
        ) {
          fieldErrors[field.key] = rule.message || `Invalid value`;
        }
      });
      this.setState({ fieldErrors });
    }
  }

  onSubmit(e) {
    e.preventDefault();
    this.props.fields.forEach((field) => {
      this.validate(field);
    });
    const hasError = Object.keys(this.state.fieldErrors).filter(
      (x) => this.state.fieldErrors[x]
    ).length;
    this.props.onSubmit(
      { ...this.state.fieldValues },
      hasError ? { ...this.state.fieldErrors } : null
    );
  }

  onExtraSubmitButton() {
    this.props.fields.forEach((field) => {
      this.validate(field);
    });
    const hasError = Object.keys(this.state.fieldErrors).filter(
      (x) => this.state.fieldErrors[x]
    ).length;
    this.props.onExtraSubmitButton(
      { ...this.state.fieldValues },
      hasError ? { ...this.state.fieldErrors } : null
    );
  }

  render() {
    const { props } = this;
    const classes = props.classes;
    const fieldValues = this.state.fieldValues; // Ensure fieldValues is correctly referenced from state
    const isCategoryScreenDefault = fieldValues["is_category_screen_default"];
    const selectedLayout = this.state.selectedLayout;
    const layoutSize = this.getLayoutSize(selectedLayout);
    return (
      <Dialog
        open={props.open}
        onClose={props.onClose}
        maxWidth="md"
        style={{ display: props.hide ? "none" : "block" }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Loader visible={props?.crudLoader}/>
        <form onSubmit={this.onSubmit.bind(this)}>
          <DialogContent style={{ overflowX: "hidden", paddingTop: 5 }}>
            <div style={{ width: 900 }}>
              <div
                style={{ display: "flex", flexDirection: "column" }}
                className="mb-1"
              >
                <Typography variant="h6" className={classes.modalTitle}>
                  {props.title}
                  {props.info && (
                    <Link to={props.info}>
                      <HelpIcon
                        color="primary"
                        style={{ width: 18, height: 18, marginLeft: 5 }}
                      />
                    </Link>
                  )}
                </Typography>
                <Typography variant="body2" className={classes.modalDesc}>
                  {props.description}
                </Typography>
                {props.notes ? (
                  <span
                    dangerouslySetInnerHTML={{ __html: props.notes }}
                    style={{ marginTop: 10, fontSize: 13, color: "#645f5f" }}
                  />
                ) : null}
              </div>

              <Text
                field={{
                  key: "name",
                  columnName: "Theme Name",
                  label: "Theme Name",
                  type: "text",
                  visible: true,
                  required: true,
                }}
                // style={{ marginBottom:"10px !important" }}
                values={props.values}
                fieldValues={this.state.fieldValues}
                fieldErrors={this.state.fieldErrors}
                validate={this.validate.bind(this)}
                onChange={this.handleFieldChange.bind(this)}
                mode={props.mode}
              />

              <div
                style={{
                  border: "1px solid #c4c4c4",
                  borderRadius: "5px",
                  padding: "5px 15px",
                }}
              >
                <div
                  style={{ display: "flex", flexDirection: "column" }}
                  className="mb-2"
                >
                  <Typography variant="h6" className={classes.modalTitle9}>
                    Please select the layout for the first screen (Call to
                    action screen)
                  </Typography>
                  <Typography variant="body2" className={classes.modalDesc}>
                    Select the layout for the call to action screen
                  </Typography>
                </div>
                <div className="row" style={{paddingLeft:12}}>
                  <RadioSelect
                    values={props.values}
                    fieldValues={this.state.fieldValues}
                    fieldErrors={this.state.fieldErrors}
                    validate={this.validate.bind(this)}
                    mode={props.mode}
                    onChange={this.handleFieldChange.bind(this)}
                    field={{
                      key: "layout",
                      options: [
                        {
                          label: "Select",
                          value: "default",
                          img: pic4,
                          number: "4x4",
                        },
                        {
                          label: "Select",
                          value: "Layout 12",
                          img: pic3,
                          number: "3x4",
                        },
                        {
                          label: "Select",
                          value: "Layout 9",
                          img: pic5,
                          number: "3x3",
                        },
                        {
                          label: "Select",
                          value: "Layout 1",
                          img: pic2,
                          number: "2x3",
                        },
                        {
                          label: "Select",
                          value: "Layout 2",
                          img: pic1,
                          number: "2x2",
                        },
                        {
                          label: "Select ",
                          value: "Layout 3",
                          img: pic,
                          number: "1x2",
                        },
                      ],
                    }}
                  />
                </div>

                <div className="row" style={{ marginTop: "5px", height:'100%' }}>
                  <div className="col-sm-12">
                  <LabelledOutline label="Colors">
                      <div className="row">
                        <div className="col-sm-4">
                          <Text
                            field={{
                              key: "primary_color",
                              columnName: "Primary color",
                              label: "Primary color",
                              value: "#000000",
                              type: "color",
                              visible: true,
                            }}
                            values={props.values}
                            fieldValues={this.state.fieldValues}
                            fieldErrors={this.state.fieldErrors}
                            validate={this.validate.bind(this)}
                            onChange={this.handleFieldChange.bind(this)}
                            mode={props.mode}
                            size="small"
                          />
                        </div>
                        <div className="col-sm-4">
                          <Text
                            field={{
                              key: "secondary_color",
                              columnName: "Text Color",
                              label: "Text Color",
                              value: "#000000",
                              type: "color",
                              visible: true,
                            }}
                            values={props.values}
                            fieldValues={this.state.fieldValues}
                            fieldErrors={this.state.fieldErrors}
                            validate={this.validate.bind(this)}
                            onChange={this.handleFieldChange.bind(this)}
                            mode={props.mode}
                            size="small"
                          />
                        </div>
                        <div className="col-sm-4 mb-3">
                          <Text
                            field={{
                              key: "text_button_color",
                              columnName: "Button Text Color",
                              label: "Button Text Color",
                              value: "#000000",
                              type: "color",
                              visible: true,
                            }}
                            values={props.values}
                            fieldValues={this.state.fieldValues}
                            fieldErrors={this.state.fieldErrors}
                            validate={this.validate.bind(this)}
                            onChange={this.handleFieldChange.bind(this)}
                            mode={props.mode}
                            size="small"
                          />
                        </div>
                      </div>
                    </LabelledOutline>
                  </div>
                  <div className="col-sm-12">
                    <LabelledOutline label="For Option [*Unavailable on generic software]">
                      <div className="row">
                        <div className="col-sm-6">
                          {/* Adding padding inside the column to push the switch right */}
                          <div style={{ paddingLeft: '20px' }}> {/* Adjust padding as needed */}
                            <SwitchSelect
                              field={{
                                key: "help_option",
                                label: "Help Option",
                                defaultValue: true,
                              }}
                              values={props?.values}
                              fieldValues={this.state.fieldValues}
                              onChange={this.handleFieldChange.bind(this)}
                            />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <SwitchSelect_skip
                            field={{
                              key: "is_category_screen_default",
                              label: "Category Screen",
                              defaultValue: false,
                            }}
                            values={props?.values}
                            fieldValues={this.state.fieldValues}
                            onChange={this.handleFieldChange.bind(this)}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-sm-6">
                          <div style={{ paddingLeft: '20px' }}>
                            <SwitchSelect
                              field={{
                                key: "show_product_info",
                                label: "Product Info",
                                defaultValue: true,
                              }}
                              values={props?.values}
                              fieldValues={this.state.fieldValues}
                              onChange={this.handleFieldChange.bind(this)}
                            />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <SwitchSelect
                            field={{
                              key: "show_category_dropdown",
                              label: "Category Dropdown",
                              defaultValue: true,
                            }}
                            values={props?.values}
                            fieldValues={this.state.fieldValues}
                            onChange={this.handleFieldChange.bind(this)}
                            disabled={isCategoryScreenDefault}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-sm-6">
                          <div style={{ paddingLeft: '20px' }}>
                            <SwitchSelect_skip
                              field={{
                                key: "disable_thank_you_screen",
                                label: "Skip Thank You screen",
                                defaultValue: false,
                              }}
                              values={props?.values}
                              fieldValues={this.state.fieldValues}
                              onChange={this.handleFieldChange.bind(this)}
                            />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <SwitchSelect_skip
                            field={{
                              key: "enable_category_search",
                              label: "Product Search",
                              defaultValue: false,
                            }}
                            values={props?.values}
                            fieldValues={this.state.fieldValues}
                            onChange={this.handleFieldChange.bind(this)}
                            disabled={isCategoryScreenDefault}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-sm-6">
                          <div style={{ paddingLeft: '20px', marginBottom: '8px' }}>
                            <SwitchSelect
                              field={{
                                key: "show_accessibility",
                                label: "Accessibility Option",
                                defaultValue: true,
                              }}
                              values={props?.values}
                              fieldValues={this.state.fieldValues}
                              onChange={this.handleFieldChange.bind(this)}
                            />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div style={{ marginBottom: '8px' }}>
                            <SwitchSelect_skip
                              field={{
                                key: "disable_price_info_cc",
                                label: "Disable Price Info and Credit Card*",
                                defaultValue: false,
                              }}
                              values={props?.values}
                              fieldValues={this.state.fieldValues}
                              onChange={this.handleFieldChange.bind(this)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-6">
                          <div style={{ paddingLeft: '20px', marginBottom: '8px' }}>
                          <SwitchSelect_skip
                              field={{
                                key: "disable_call_to_action",
                                label: "Disable Call To Action",
                                defaultValue: false,
                              }}
                              values={props?.values}
                              fieldValues={this.state.fieldValues}
                              onChange={this.handleFieldChange.bind(this)}
                            />
                          </div>
                        </div>
                        {props.isLanguageDropDown &&
                        <div className="col-sm-6">
                          <div style={{  marginBottom: '8px' }}>
                          <SwitchSelect_skip
                              field={{
                                key: "enable_language_dropdown",
                                label: "Enable Language Dropdown*",
                                defaultValue: false,
                              }}
                              values={props?.values}
                              fieldValues={this.state.fieldValues}
                              onChange={this.handleFieldChange.bind(this)}
                            />
                          </div>
                        </div>
  }
                      </div>
                    </LabelledOutline>
                  </div>


                </div>
              </div>
              <div
                style={{
                  border: "1px solid #c4c4c4",
                  borderRadius: "5px",
                  padding: "10px 15px",
                  margin: "10px 0px",
                }}
              >
 <Typography
  variant="h6"
  className={classes.modalTitle9}
  style={{ marginBottom: 8 }}
>
  Backgrounds <span style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: 12 }}>[Suggested file types: .gif, .png, .jpg]</span>
</Typography>
<div className="row">
  <div className="col-sm-4" style={{ paddingRight: 10 }}>
    <LabelledOutline label="BG image(1692px*2118px)">
      <File_with_preview
        accept=".gif, .png, .jpg, .jpeg"
        onChange={this.handleFieldChange.bind(this)}
        field={{
          key: "background_img_url",
          maxWidth: "100%",
          label: "Background Image",
          type: "file",
        }}
        value={this.state.fieldValues["background_img_url"]}
      />
    </LabelledOutline>
  </div>
  <div className="col-sm-4" style={{ paddingRight: 10, paddingLeft: 10 }}>
    <LabelledOutline label={`Products layout(${layoutSize})`}>
      <File_with_preview
        accept=".gif, .png, .jpg, .jpeg"
        onChange={this.handleFieldChange.bind(this)}
        field={{
          key: "item_background_img_url",
          maxWidth: "100%",
          label: "Item Background",
          type: "file",
        }}
        value={this.state.fieldValues["item_background_img_url"]}
      />
    </LabelledOutline>
  </div>
  <div className="col-sm-4" style={{ paddingLeft: 10 }}>
    <LabelledOutline label="Category Background (832px*1100px)">
      <File_with_preview
        accept=".gif, .png, .jpg, .jpeg"
        onChange={this.handleFieldChange.bind(this)}
        field={{
          key: "category_bg_image",
          maxWidth: "100%",
          label: "Category background",
          type: "file",
        }}
        value={this.state.fieldValues["category_bg_image"]}
      />
    </LabelledOutline>
  </div>
</div>
<div className="row" style={{ marginTop: 20 }}>
  <div className="col-sm-12">
    <LabelledOutline label="For Opacity">
      <div className="row" style={{ display: 'flex', justifyContent: 'space-between', padding: '6px 0px' }}>
        <div className="col-sm-4" style={{ paddingRight: 10 }}>
          <Text
            field={{
              key: "background_opacity",
              columnName: "Background opacity",
              label: "Background opacity",
              type: "number",
              visible: true,
              required: true,
              min: 1,
              max: 100,
              defaultValue: 100
            }}
            values={props.values}
            fieldValues={this.state.fieldValues}
            fieldErrors={this.state.fieldErrors}
            validate={this.validate.bind(this)}
            onChange={this.handleFieldChange.bind(this)}
            mode={props.mode}
            size="small"
            style={{ width: '100%' }}
          />
        </div>
        <div className="col-sm-4" style={{ paddingRight: 10, paddingLeft: 10 }}>
          <Text
            field={{
              key: "item_background_opacity",
              columnName: "Item background opacity",
              label: "Item background opacity",
              type: "number",
              visible: true,
              required: true,
              min: 1,
              max: 100,
              defaultValue: 100
            }}
            values={props.values}
            fieldValues={this.state.fieldValues}
            fieldErrors={this.state.fieldErrors}
            validate={this.validate.bind(this)}
            onChange={this.handleFieldChange.bind(this)}
            mode={props.mode}
            size="small"
            style={{ width: '100%' }}
          />
        </div>
        <div className="col-sm-4" style={{ paddingLeft: 10 }}>
          <Text
            field={{
              key: "category_background_opacity",
              columnName: "Category Background opacity",
              label: "Category Background opacity",
              type: "number",
              visible: true,
              required: true,
              min: 1,
              max: 100,
              defaultValue: 100
            }}
            values={props.values}
            fieldValues={this.state.fieldValues}
            fieldErrors={this.state.fieldErrors}
            validate={this.validate.bind(this)}
            onChange={this.handleFieldChange.bind(this)}
            mode={props.mode}
            size="small"
            style={{ width: '100%' }}
          />
        </div>
      </div>
    </LabelledOutline>
  </div>
</div>


              </div>

              <div
                style={{
                  border: "1px solid #c4c4c4",
                  borderRadius: "5px",
                  padding: "10px 15px",
                  margin: "10px 0px",
                }}
              >
                <Typography
                  variant="h6"
                  className={classes.modalTitle9}
                  style={{ marginBottom: 8 }}
                >
                  Theme Images  <span style={{color: 'rgba(0, 0, 0, 0.54)',fontSize:12}}>[Suggested file types: .gif, .png, .jpg]</span>
                </Typography>
                
                  <div className="row">

                    <div className="col-sm-3" style={{paddingRight:0}}>
                    <LabelledOutline label="Logo (650px*150px)">
                      <File_with_preview
                        onChange={this.handleFieldChange.bind(this)}
                        field={{
                          key: "logo_url",
                          maxWidth: "100%",
                          label: "Logo",
                          type: "file",
                        }}
                        accept=".gif, .png, .jpg, .jpeg"
                        value={this.state.fieldValues["logo_url"]}
                      />
                       
                       </LabelledOutline>
                    </div>
                    <div className="col-sm-3" style={{paddingRight:0,paddingLeft:5}}>
                    <LabelledOutline label="Call to action(1300px*350px)">
                      <File_with_preview
                        accept=".gif, .png, .jpg, .jpeg"
                        onChange={this.handleFieldChange.bind(this)}
                        field={{
                          key: "call_to_action_img_url",
                          maxWidth: "100%",
                          label: "Call to action Image",
                          type: "file",
                        }}
                        value={this.state.fieldValues["call_to_action_img_url"]}
                      />
                       
                       </LabelledOutline>
                    </div>
                   

                    <div className="col-sm-3" style={{paddingRight:0,paddingLeft:5}}>
                    <LabelledOutline label="Thank you screen(810px*935px)">
                      <File_with_preview
                        onChange={this.handleFieldChange.bind(this)}
                        accept=".gif, .png, .jpg, .jpeg"
                        field={{
                          key: "thank_you_screen_img_url",
                          maxWidth: "100%",
                          label: "Thank you screen image",
                          type: "file",
                        }}
                        value={this.state.fieldValues["thank_you_screen_img_url"]}
                      />
                       
                    </LabelledOutline>
                    </div>
                    
                    <div className="col-sm-3" style={{paddingLeft:5}}>
                    <LabelledOutline label="Help Dialog(375px*695px)">
                      <File_with_preview
                        accept=".gif, .png, .jpg, .jpeg"
                        onChange={this.handleFieldChange.bind(this)}
                        field={{
                          key: "help_dialog_image",
                          maxWidth: "100%",
                          label: "Help Dialog image",
                          type: "file",
                        }}
                        value={this.state.fieldValues["help_dialog_image"]}
                      />
                      
                    </LabelledOutline>
                    </div>

                   

                  </div>

             
              </div>


              {/* PAYMENT IMAGES */}

              <div
                style={{
                  border: "1px solid #c4c4c4",
                  borderRadius: "5px",
                  padding: "10px 15px",
                  margin: "10px 0px",
                }}
              >
            <Typography
                  variant="h6"
                  className={classes.modalTitle9}
                  style={{ marginBottom: 8 }}
                >
                  Payment Images  <span style={{color: 'rgba(0, 0, 0, 0.54)',fontSize:12}}>[Suggested file types: .gif, .png, .jpg, .jpeg]</span>
                </Typography>
               
                  <div className="row">

                    <div className="col-sm-4" style={{paddingRight:0}}>
                    <LabelledOutline label="Credit card (250px*250px)">
                      <File_with_preview
                        onChange={this.handleFieldChange.bind(this)}
                        field={{
                          key: "credit_card_img_url",
                          maxWidth: "100%",
                          label: "Credit card",
                          type: "file",
                        }}
                        accept=".gif, .png, .jpg, .jpeg"
                        value={this.state.fieldValues["credit_card_img_url"]}
                      />
                     
                       </LabelledOutline>
                    </div>
                    <div className="col-sm-4" style={{paddingRight:0,paddingLeft:5}}>
             
                    </div>
                  </div>
              </div>
            </div>
          </DialogContent>
          <DialogActions className={classes.modalActions}>
            <div>
              {props.extraButtonText ? (
                <Button
                  onClick={() => {
                    props.onExtraButton();
                  }}
                  variant="outlined"
                  color="primary1"
                >
                  {props.extraButtonLoading ? (
                    <CircularProgress
                      size={16}
                      style={{ marginLeft: 0 }}
                      className="mr-2"
                    />
                  ) : null}{" "}
                  {props.extraButtonText}
                </Button>
              ) : props.extraComponent ? (
                props.extraComponent
              ) : null}
            </div>
            <div>
              {props.closable ? (
                <Button
                  onClick={() => {
                    props.onClose();
                    this.setState({ fieldValues: {} });
                  }}
                  variant="outlined"
                  color="primary"
                  className="mr-2"
                >
                  {props.cancelText}
                </Button>
              ) : null}
              {props?.extraSubmitButtonText &&
                <Button
                  className="mr-2"
                  variant="contained"
                  color="primary"
                  onClick={this.onExtraSubmitButton.bind(this)}
                  disabled={props?.crudLoader}
                >
                  {props.extraSubmitButtonText}
                </Button>
              }
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={props?.crudLoader}
              >
                {props.okText}
              </Button>
            </div>
          </DialogActions>
        </form>
        {props.loading && <OverlaySpinner />}
      </Dialog>
    );
  }
}

CrudDialog.defaultProps = {
  onRef: () => {},
  open: false,
  fields: [],
  onClose: () => {},
  onFieldChange: () => {},
  title: "Dialog Title",
  onSubmit: () => {},
  onExtraSubmitButton: () => {},
  values: {},
  okText: "Create",
  cancelText: "Cancel",
  info: false,
  crudLoader: false,
  mode: "Add",
  closable: true,
  extraButtonLoading: false,
  hide: false,
};

const OverlaySpinner = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height={"100%"}
      width={"100%"}
      position="fixed"
      top={0}
      left={0}
    >
      <CircularProgress />
    </Box>
  );
};
export default withStyles(styles)(CrudDialog);
