import React, { useState,useEffect } from "react";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { isBoolean } from "lodash";
const SwitchSelect_skip = (props) => {
  const { field,values,fieldValues,disabled } = props;
  const [check, setCheck] = useState(false);
  useEffect(()=>{
    if (typeof values[field.key] ==="undefined" ){
      props.onChange(field, field.defaultValue || false);
    }else{
      setCheck(Boolean (values[field.key]||false))
      props.onChange(field, values[field.key]);
    }
  },[]);
  useEffect(()=>{
    console.log("useEffect called for  mutation");
    
    if (field.key==="enable_category_search" ){
      setCheck(fieldValues.enable_category_search)
    }
    else if (field.key==="show_category_dropdown"){
      setCheck(fieldValues.show_category_dropdown)
    }
    else if (field.key==="disable_thank_you_screen" ){
      setCheck(fieldValues.disable_thank_you_screen)
    }
    else if (field.key==="help_option"){
      setCheck(fieldValues.help_option)
    }
    else if (field.key==="show_accessibility" ){
      setCheck(fieldValues.show_accessibility)
    }
    else if (field.key==="show_product_info"){
      setCheck(fieldValues.show_product_info)
    }
    else if (field.key==="enable_language_dropdown"){
      setCheck(fieldValues.enable_language_dropdown)
    }
    else if (field.key==="disable_call_to_action"){
      setCheck(fieldValues.disable_call_to_action)
    }
    else if (field.key==="is_category_screen_default" ){
      setCheck(fieldValues.is_category_screen_default)
    }
  },[fieldValues.enable_category_search,fieldValues.show_category_dropdown,
    fieldValues.disable_thank_you_screen,fieldValues.help_option,
    fieldValues.show_accessibility,fieldValues.show_product_info, fieldValues.enable_language_dropdown,
  fieldValues.disable_call_to_action]);



  const handleChange = ({target}) => {
    props.onChange(field, target.checked);
    setCheck(target.checked);
  };
  return (
    <div className="d-flex ">
      <FormControlLabel
       style={{marginRight: 0}}
            control={
                  <Switch
                  checked={check}
                  // defaultValue={check}
                  // value={check}
                  // checked={ values[field.key]}
                  disabled={disabled}

                  color="primary"
                  onChange={handleChange} 
                  key={field.key}
                  id={field.key}
                  name={field.key} />}
                    label={field.label}
                    />

    </div>
  );
};

export default SwitchSelect_skip;
