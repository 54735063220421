import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { withStyles } from "@material-ui/styles";
import { TAB_GREEN_COLOR } from "../../../config";
import styles from "./styles";
import { useLocation } from "react-router-dom";

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: 'capitalize',
    fontWeight: 'normal',
    fontSize: '14px',
    padding: 0,
    // marginRight: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    [theme.breakpoints.up("md")]: {
      marginRight: theme.spacing(2)
    },
    '&$selected': {
      color: `${theme.palette.primary.main} !important`,
      fontWeight: 'bold',
    },
    '&:focus': {
      color: '#3A3A3C !important',
    },
  },
  selected: {},
}))((props) => {
  const tabRef = useRef(null);
  const handleTabTap = (e) => {
    if (!props.edited) return
    if (
      window.confirm("Updates will be lost, Do you want to continue ?") == true
    ) {
      props.setEdited(false);
    } else {
      e.stopPropagation();
    }
  };
  return (
    <div onClickCapture={handleTabTap}>
      <Tab
        ref={tabRef}
        disableRipple
        {...props}
      />
    </div>
  );
});

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {children}
    </div>
  );
};

const TabsGenerator = ({ tabsList, activeTabs }) => {
  const classes = styles();
  const [activeTab, setActiveTab] = useState(activeTabs);
  const [isEdited, setEdited] = useState(false)
  const location = useLocation();

  const currentUser = useSelector((state) => state.userReducer.current_user);
  const theme = useSelector((state) => state.userReducer.theme);

  const filteredTabList = useMemo(
    () =>
      tabsList.filter((x) =>
        typeof x.visible !== "undefined" ? x.visible : true
      ),
    [tabsList]
  );

  const handleTabChange = (_, newValue) => {
    setActiveTab(newValue);
    window.history.replaceState(null, null, "?tab="+newValue);
  };

  useEffect(() => {
    const queryParam = new URLSearchParams(window.location.search);
    const tabName = queryParam.get("tab");

    if (tabName) {
      setActiveTab(tabName);
    }
  }, [location]);

  return (
    <div style={{ overflowX: "hidden", overflowY: "hidden", minHeight: 500 }}>
      <Tabs
        value={activeTab}
        indicatorColor="primary"
        textColor="primary"
        color="secondary"
        className={classes.root}
        onChange={handleTabChange}
        variant="scrollable"
        scrollButtons="auto"
        style={{
          marginBottom: "1rem",
          backgroundColor: "#F7F8FA",
          height: "73px",
          paddingTop: "13px",
          boxShadow: "1px 0px 6px 0px #9e9e9e",
          paddingRight: "170px",
        }}
        TabIndicatorProps={{
          style: {
            height: 2,
            backgroundColor:
              currentUser?.type === "SA"
                ? TAB_GREEN_COLOR
                : theme?.palette?.primary?.dark,
          },
        }}
        aria-label="dashboard"
      >
        {filteredTabList.map(({ label, value }) => (
          <AntTab
            setEdited={setEdited}
            style={{ outline: "none", margin: "0 8px" }}
            key={value}
            selected={activeTab === value}
            value={value}
            label={label}
            edited={isEdited}
          />
        ))}
      </Tabs>

      {/** Render tab screens here */}

      {filteredTabList.map(
        ({ label, value, component }) =>
          activeTab === value && (
            <TabPanel key={value} value={value} index={value}>
              {React.cloneElement(component, { isEdited, setEdited, setActiveTab })}
            </TabPanel>
          ))}
    </div>
  );
};

export default TabsGenerator;
