import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import styles from "./styles";
import "./styles.css";
import _ from "lodash";
import { AddButton, EditButton, DeleteButton, ExportButton } from "../common/Buttons";
import SearchBox from "../common/SearchBox";
import ContentHeader from "../common/ContentHeader";
import TableGenerator from "../common/TableGenerator";
import CrudDialog from "../common/CrudDialog";
import { useSnackbar } from "notistack";
import moment from 'moment';
import InfoModal from "components/Common/AlertDialog/info_dialogue";
import { ImportButton } from "components/Common/Buttons";
import {
  Grid
} from "@material-ui/core";
import {
  handleServerErrors,
  handleMultiFilterSearch,
} from "../../utiles/helpers";
import { getTimeZoneDifference } from "utils/helpers";
import { CircularProgress } from "@material-ui/core";
import AsyncAutoComplete from "components/Common/AsyncAutoComplete";


const filterLabels = {
  location_name: "location_name"
};

const deviceTypeFields = ["location_name"];

const SupportLocation = (props) => {
  const classes = styles();
  const [addModal, setAddModal] = useState(false);
  const [locationList, setLocationList] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [page, setPage] = useState(0);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [firstPage, setFirstPage] = useState(null);
  const [lastPage, setLastPage] = useState(null);
  const [ordering, setOrdering] = useState("id");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [forceDeleteModal, setForceDeleteModal] = useState(false);
  const [categorySelected, setCategorySelected] = useState([]);
  const [vmValue, setVmValue] = useState("all");
  const [categoryValue, setCategoryValue] = useState("all");
  const [filterable, setFilterable] = useState(deviceTypeFields);
  const [query, setQuery] = useState("");
  const [loader, setLoader] = useState(false);
  const [devicesList, setDevicesList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [buttonDisable, setButtonDisable] = useState(true);
  const [isMutating, setIsMutating] = useState(false);
  const [searchQuery, setSearchQuery] = useState({});
  const [crudLoader, setCrudLoader] = useState(false)
  const [crudDeleteLoader, setCrudDeleteLoader] = useState(false)
  const [devicesLoading, setDevicesLoading] = useState(false)
  const [categoriesLoading, setCategoriesLoading] = useState(false)
  const [importError, setImportError] = useState([]);
  const [isInfo, setIsInfo] = useState(false);
  const [languageList, setLanguageList] = useState([])
  const [language, setLanguage] = useState({label: 'English', value: 'English'});
  const hiddenFileInput = useRef(null);


  const current_user = useSelector((state) => state.userReducer.current_user);
  const { enqueueSnackbar } = useSnackbar();

  function getLocationByLanguage(value) {
    // Iterate over the array to find the matching language
    for (const record of value) {
      let selectedLanguage = language ? language.label : 'English'
      if (record.language === selectedLanguage) {
        return record.location_name;
      }
    }
    return null;
  }

  function getCategoryByLanguage(value) {
    // Iterate over the array to find the matching language
    for (const record of value) {
      let selectedLanguage = language ? language.label : 'English'
      if (record.language === selectedLanguage) {
        return record.category;
      }
    }
    return null;
  }

  function getAddressByLanguage(value) {
    // Iterate over the array to find the matching language    
    for (const record of value) {
      let selectedLanguage = language ? language.label : 'English'
      if (record.language === selectedLanguage) {
        return record.detailed_address;
      }
    }
    return null;
  }

  const fields = [
    {
      key: "id",
      columnName: "ID",
      form: false,
      type: "text",
      visible: true,
    },
    {
      key: "locations",
      columnName: "Location Name",
      label: "Location Name",
      type: "text",
      visible: true,
      required: true,
      render: (value) => value ? getLocationByLanguage(value) : "---",
    },
    {
      key: "locations",
      columnName: "Category Name",
      label: "Category Name",
      type: "text",
      visible: true,
      required: true,
      render: (value) => value ? getCategoryByLanguage(value) : "---",
    },
    {
      key: "latitude",
      columnName: "Latitude",
      visible: true,
      render: (value) => value || "---",
    },


    {
      key: "longitude",
      columnName: "Longitude",
      visible: true,
      render: (value) => value || "---",
    },
    {
      key: "device",
      columnName: "Device",
      label: "Device",
      type: "text",
      visible: true,
      required: true,
      render: (value) => value ? `${value.vm_name} | ${value.serial_number}` : "---",
    },
    {
      key: "locations",
      columnName: "Detailed Address",
      label: "Detailed Address",
      type: "text",
      visible: true,
      required: true,
      render: (value) => value ? getAddressByLanguage(value) : "---",
    },
    {
      key: "operator",
      columnName: "Operator",
      label: "Operator",
      type: "text",
      visible: true,
      required: true,
      render: (value) => value ? value.business_name : "---",
    },
    {
      key: "created_at",
      columnName: "Created At",
      label: "Created At",
      type: "text",
      visible: true,
      required: true,
      render: (value) => value ? moment(value).format("MM-DD-YYYY hh:mm:ss A") : "---",
    },
    {
      key: "updated_at",
      columnName: "Updated At",
      label: "Updated At",
      type: "text",
      visible: true,
      required: true,
      render: (value) => value ? moment(value).format("MM-DD-YYYY hh:mm:ss A") : "---",
    },
  ];

  const formFields = [
    [
      {
        key: "location_name",
        columnName: "Location Name",
        label: "Location Name",
        type: "text",
        visible: true,
        required: true,
      },
    ], [
      {
        key: "device",
        label: "Device",
        columnName: "Device",
        type: "autocomplete",
        show: true,
        freeSolo: false,
        options: devicesLoading ? [{ label: "Device Loading ...", value: null }] :
          devicesList.length ? devicesList.map((device) => ({
            label: `${device.vm_name} | ${device.serial_number}`,
            value: device.id,
          }))
            : [{ label: "No Device Available", value: null }],
        required: true,
        visible: true,
        render: (value) => value || "---",
      },
    ],
    [
      {
        key: "category",
        label: "Support Location category",
        columnName: "Category Name",
        type: "autocomplete",
        show: true,
        freeSolo: false,
        options: categoriesLoading ? [{ label: "Category Loading ...", value: null }] :
          categoryList?.length ? categoryList.map((categories) => ({
            label: categories.category,
            value: categories.id,
          }))
            : [{ label: "No Categories Available", value: null }],
        required: true,
        visible: true,
        render: (value) => value || "---",
      },
    ],
    [
      {
        key: "latitude",
        label: "Latitude",
        type: "number",
        validations: [{
          type: 'custom',
          value: (fieldValue) => fieldValue >= -90 && fieldValue <= 90,
          message: "Write Number between -90 to 90"
        }],
        inputProps: {
          step: "any",
          min: -90,
          max: 90,
        },
        visible: true,
        required: true,
      },
      {
        key: "longitude",
        label: "Longitude",
        type: "number",
        validations: [{
          type: 'custom',
          value: (fieldValue) => fieldValue >= -180 && fieldValue <= 180,
          message: "Write Number between -180 to 180"
        }],
        inputProps: {
          step: "any",
          min: -180,
          max: 180,
        },
        visible: true,
        required: true,
      },
    ],
    [
      {
        key: "detailed_address",
        columnName: "Detailed Address",
        label: "Detailed Address",
        type: "text",
        visible: true,
        required: true,
      },
    ],
  ];


  const setup = () => {
    setLoader(true);
    setLocationList([]);
    setCategorySelected([]);
  };
  const handleRes = (data) => {
    setLocationList(data.results.map((x) => ({ ...x, id: x.id })));
    setNextPage(data.next);
    setPreviousPage(data.previous);
    setFirstPage(data.first);
    setLastPage(data.last);
    setLoader(false);
    setDataCount(data.count);
  };

  useEffect(() => {
    getLanguages();
  }, [])

  const getLanguages = async () => {
    window
      .axiosIns("/languages", { params: { all: true, ordering: 'langauge' } })
      .then(({ data = {} }) => {
        let languages = data.results?.map(
          (x) => ({ label: x.langauge, value: x.id }))
        setLanguageList(languages);
      })
      .catch((err) => {
        if (err.detail) {
          enqueueSnackbar(err.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not get brands. Try again."
          );
        }
      });
  };

  const getDevices = async (data) => {
    setDevicesLoading(true)
    try {
      const params = {
        all: true,
        ordering: 'vm_name',
      };
      const { data } = await window.axiosIns("/device", { params });
      setDevicesList(data?.data?.results);
    } catch (err) {
    } finally {
      setDevicesLoading(false)
    }
  }

  const getCategories = async (data) => {
    try {
      setCrudLoader(true);
      setCategoriesLoading(true)
      const params = {
        all: true,
        ordering: 'category',
      };
      const { data } = await window.axiosIns("/location_category", { params });
      setCategoryList(
        _.map(data.results, ({ id, categories }) => {
          const selectedCategory = categories.find((lang) => lang.language === language.label);
          return {
            label: selectedCategory ? selectedCategory.category : "", // Use the category if found, otherwise an empty string
            value: id,
          };
        })
      );
      
      // setCategoryList(data?.results);
    } catch (err) {
    } finally {
      setCrudLoader(false);
      setCategoriesLoading(false)
    }
  }

  const getLocationList = async (order, max, customPage = page) => {
    const params = {
      ...searchQuery,
      limit: max ? max : rowsPerPage,
      ordering: order ? order : ordering,
      device: vmValue,
      _scope: 'AND',
      category: categoryValue,
      state: 'all',
      page: customPage + 1,
    };
    try {
      setup();
      const { data } = await window.axiosIns("support_location", { params });
      handleRes(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getLocationList();
  }, [vmValue, categoryValue]);

  useEffect(() => {
    getDevices();
    getCategories();
  }, []);


  const handleAdd = (data) => {
    setIsMutating(true);
    setCrudLoader(true)
    window.axiosIns
      .post(`support_location`, data)
      .then(() => {
        setCrudLoader(false)
        setAddModal(false);
        enqueueSnackbar("Support Location Added successfully.");
        setTimeout(() => {
          setIsMutating(false);
        }, 200);
        getLocationList();
      })
      .catch((err) => {
        if (err.response.data.detail) {
          enqueueSnackbar(err.response.data.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not add Support Location. Try again."
          );
        }
        setIsMutating(false);
      })
      .then(() => {
        setCategorySelected([]);
        setCrudLoader(false)
      })
  };

  const handleEdit = (data) => {
    setIsMutating(true);
    setCrudLoader(true);
    const moduleId = categorySelected[0].id;
    window.axiosIns
      .put(`support_location/${moduleId}`, data)
      .then(() => {
        setCrudLoader(false)
        setEditModal(false);
        enqueueSnackbar("Support Location edited successfully.");

        setTimeout(() => {
          setIsMutating(false);
        }, 200);
      })
      .catch((err) => {
        if (err.detail) {
          enqueueSnackbar(err.detail);
        } else if (((err.response || {}).data || {}).detail) {
          enqueueSnackbar(((err.response || {}).data || {}).detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not edit Support Location. Try again."
          );
        }
        setCrudLoader(false)
        setEditModal(false);
        setIsMutating(false);
      })
      .then(() => {
        setLocationList([]);
        setCategorySelected([]);
        getLocationList();
      });
  };

  const handleFilter = (arr) => {
    setFilterable(arr);
    if (query !== "") {
      let searchFilter = handleMultiFilterSearch(filterLabels, arr, query);
      setup();
      setPage(0);
      window
        .axiosIns("support_location", {
          params: { ...searchFilter, limit: rowsPerPage, ordering, state: 'all' },
        })
        .then((data = {}) => {
          handleRes(data.data);
        })
        .catch((err) => {
          setLoader(false);
        });
    }
  };

  const changePage = (url) => {
    setup();
    window.axiosIns
      .get(url)
      .then(({ data = {} }) => {
        handleRes(data);
      })
      .catch((err) => {
        setLoader(false);
        if (err.detail) {
          enqueueSnackbar(err.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not get Support Location. Try again."
          );
        }
      });
  };

  const handleSearch = (value) => {
    setQuery(value);
    let searchFilter = {};
    if (value !== "") {
      searchFilter = handleMultiFilterSearch(filterLabels, filterable, value);
    }
    setSearchQuery(searchFilter);
    setup();
    setPage(0);
    window
      .axiosIns("support_location", {
        params: { ...searchFilter, limit: rowsPerPage, ordering, state: 'all' },
      })
      .then((data = {}) => {
        handleRes(data.data);
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  const supportCategoriesImport = async (event) => {
    const fileUploaded = event.target.files[0];
    const formData = new FormData();
    formData.append('template', fileUploaded);

    window.axiosIns
      .post(`/support_location/import_data`, formData)
      .then((resp) => {
        const { data: { data } } = resp;
        const message = resp.data.message;
        const errors = [];
        if (message === 'Import failed!') {
          setImportError(data); setIsInfo(true)
        };
        enqueueSnackbar(resp?.data?.message ||
          "Support Location(s) Imported Successfully.", { autoHideDuration: 3000 });
      })
      .catch((err) => {
        if (err.response.data.detail) {
          enqueueSnackbar(err.response.data.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not import Support Location(s). Try again."
          );
        }
      })
      .then(() => {
        getLocationList();
        setCategorySelected([]);
      });
  };

  const handleDelete = ({ force } = {}) => {
    setCrudDeleteLoader(true)

    categorySelected.forEach((val, index) => {
      window.axiosIns
        .delete(
          `support_location/${val.id}${force ? "?force=true" : ""}`
        )
        .then(() => {
          setCrudDeleteLoader(false)
          setDeleteModal(false);
          setForceDeleteModal(false);
          if (categorySelected.length === index + 1) {
            if (
              locationList.length - categorySelected.length === 0 &&
              page > 0
            ) {
              setPage(page - 1);
              changePage(previousPage);
            } else {
              getLocationList();
            }
            enqueueSnackbar("Support Location(s) deleted successfully.");
          }
        })
        .catch((err) => {
          if (err.detail) {
            enqueueSnackbar(err.detail);
          } else if (err.response.data.detail) {
            enqueueSnackbar(err.response.data.detail);
          } else {
            handleServerErrors(
              err,
              enqueueSnackbar,
              "Could not delete Support Location. Try again."
            );
          }
          setCrudDeleteLoader(false)
        });
    });
  };

  const SupportLocationCategoryExport = () => {
    let params = {
      ...searchQuery,
      device: vmValue,
      category: categoryValue,
      _scope: 'AND',
      state: "all",
      tz: getTimeZoneDifference(),
    };
    let ids = categorySelected.map((value) => value?.id);
    if (ids.length > 0) params["id"] = ids.join(",");

    window.axiosIns("/support_location/export_data", {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
      },
      params: params
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Support-Location-Summary-${moment().format("MM-DD-YYYY")}.xlsx`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => console.log(error));
  }

  const handleImportClick = () => {
    hiddenFileInput.current.value = null;
    hiddenFileInput.current.click();
  };

  return (
    <div id="sa-modules-wrapper" className={classes.wrapper}>
      <ContentHeader
        description="All Support Location are listed here. You can create a new Support Location here."
      />

      <div className={classes.toolbar}>
      <Grid
  container
  direction="row"
  wrap="nowrap"
  alignItems="center"
  justifyContent="flex-start"
  spacing={1}
  style={{ overflowX: 'auto' }}
  className="align-center"
>
  <Grid item>
    <DeleteButton
      disabled={categorySelected.length <= 0 || current_user.type === "SU"}
      label="Delete"
      onClick={() => {
        if (categorySelected[0]?.is_location_associated) {
          setForceDeleteModal(true);
        } else {
          setDeleteModal(true);
        }
      }}
    />
  </Grid>
  {current_user.type !== "SU" && (
    <Grid item>
      <ExportButton
        label="Export"
        onClick={() => SupportLocationCategoryExport()}
      />
    </Grid>
  )}
  {current_user.type !== "SU" && (
    <Grid item>
      <ImportButton
        label="Import"
        onClick={handleImportClick}
      />
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={supportCategoriesImport}
        style={{ display: "none" }}
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      />
    </Grid>
  )}
  <Grid item style={{ minWidth: "100px" }}>
    <Autocomplete
      className={classes.field}
      id="order_type"
      style={{ width: 200, marginRight: 10 }}
      onChange={(_, val) => setVmValue(val?.value)}
      options={[
        { label: "All", value: "all" },
        ..._.map(devicesList, ({ id, serial_number, vm_name }) => ({
          label: `${vm_name ? `${vm_name} ||` : ""} ${serial_number}`,
          value: id,
        })),
      ]}
      getOptionLabel={(option) => option.label}
      openOnFocus={true}
      defaultValue={{ label: "All", value: "all" }}
      loading={devicesLoading}
      disabled={devicesLoading}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Vending Machines"
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {devicesLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
          variant="outlined"
          size="small"
        />
      )}
    />
  </Grid>
  <Grid item style={{ minWidth: "140px" }}>
    <AsyncAutoComplete
      onChange={(val) => setLanguage(val)}
      value={language}
      defaultOption={language}
      options={languageList}
      required
      label="Select Language"
      disabled={isMutating}
    />
  </Grid>
  { language?.label =='English' &&
  <Grid item style={{ minWidth: "150px" }}>
    <SearchBox
      multiple={true}
      query={query}
      placeholder="Search Support Location Name"
      onChange={handleFilter}
      fields={deviceTypeFields}
      selectedFields={filterable}
      handleSearch={handleSearch}
    />
  </Grid>
}
</Grid>


      </div>
      <div className={classes.content}>
        <TableGenerator
          searchQuery={query}
          initialSort={"id"}
          searchColumnsFilter={true}
          fields={fields}
          loader={loader}
          data={locationList}
          currentPage={page}
          handleSortChange={(ordering) => {
            setOrdering(ordering);
            getLocationList(ordering);
          }}
          onPageChange={(page, direction) => {
            setPage(page);
            if (direction === "next") {
              changePage(nextPage);
            } else if (direction === "back") {
              changePage(previousPage);
            } else if (direction === "first") {
              changePage(firstPage);
            } else if (direction === "last") {
              changePage(lastPage);
            }
          }}
          backendPagination={true}
          onRowPerPageChange={(rows) => {
            getLocationList(null, rows, 0);
            setPage(0);
            setRowsPerPage(rows);
          }}
          dataCount={dataCount}
          selectedRecords={categorySelected}
          rowOnePage={10}
          onChangeSelected={(categorySelected) =>
            setCategorySelected(categorySelected)
          }
        />
        <CrudDialog
          title="Add Support Location "
          okText="Add Support Location "
          fields={formFields}
          submitButtonDisabled={buttonDisable || isMutating}
          onFieldChange={(values, callBackValue) => {
            setButtonDisable(false);
          }}
          description="Please fill in the details below."
          crudLoader={crudLoader}
          onSubmit={(values, hasErrors) => {
            handleAdd(values);
          }}
          open={addModal}
          onClose={() => {
            setAddModal(false);
            setButtonDisable(true);
          }}
        />
        <CrudDialog
          title="Edit Support Location "
          okText="Save"
          description="Please edit the details below."
          crudLoader={crudLoader}
          fields={formFields}
          submitButtonDisabled={isMutating}
          values={{
            ...categorySelected[0],
            device: categorySelected[0] ? categorySelected[0].device.id : null,
            category: categorySelected[0] ? categorySelected[0].category.id : null
          }}
          onSubmit={(values) => {
            handleEdit(values);
          }}
          open={editModal}
          onClose={() => setEditModal(false)}
        />
        <CrudDialog
          title="Delete Support Location "
          description="Are you sure you want to delete the Support Location ?"
          okText="Delete"
          onSubmit={() => handleDelete()}
          open={deleteModal}
          crudLoader={crudDeleteLoader}
          onClose={() => setDeleteModal(false)}
        />

        <CrudDialog
          title="Delete Support Location "
          description="Deleting this Support Location will also delete all the associated data you have. Do you want to proceed?"
          okText="Yes"
          cancelText="No"
          onSubmit={() => handleDelete({ force: true })}
          open={forceDeleteModal}
          onClose={() => setForceDeleteModal(false)}
        />
        <InfoModal
          title="Import Errors"
          data={importError || []}
          open={isInfo}
          close={() => setIsInfo(false)}
        />
      </div>
    </div>
  );
};
export default withStyles({}, { withTheme: true })(SupportLocation);
