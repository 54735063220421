export const UPDATE_CURRENT_USER = "UPDATE_CURRENT_USER";
export const UPDATE_ACTIVE_ROUTE = "UPDATE_ACTIVE_ROUTE";
export const UPDATE_ACTIVE_SUBROUTE_INDEX = "UPDATE_ACTIVE_SUBROUTE_INDEX";
export const SET_HOST = "SET_HOST";
export const FULL_SCREEN_TOGGLE = "FULL_SCREEN_TOGGLE";
export const SET_ALERT_DATA = "SET_ALERT_DATA";
export const SET_TAKING_CALL = "SET_TAKING_CALL";
export const SET_NEW_NOTIFICATION = "SET_NEW_NOTIFICATION";
export const UPDATE_THEME = "UPDATE_THEME";
export const APP_LOGO = "APP_LOGO";
export const SET_GLOBAL_LOADER = "SET_GLOBAL_LOADER";
export const SET_INITIALY_LOADED = "SET_INITIALY_LOADED";
export const UPDATE_PARENT_USER = "UPDATE_PARENT_USER";
export const OPERATOR_TOGGLE = "OPERATOR_TOGGLE";


//Kitting Plan
export const SET_KITTING_PLAN = "SET_KITTING_PLAN";
export const REMOVE_ADD_KITTING_PLAN_FORM = "REMOVE_ADD_KITTING_PLAN_FORM";
export const SET_KITTING_PLAN_FORM = "SET_KITTING_PLAN_FORM";
export const START_KITTING_PLAN_LOADING = "START_KITTING_PLAN_LOADING";
export const STOP_KITTING_PLAN_LOADING = "STOP_KITTING_PLAN_LOADING";

//QR Payment
export const QR_DATA_REQUEST = "QR_DATA_REQUEST";
export const QR_DATA_SUCCESS = "QR_DATA_SUCCESS";
export const QR_DATA_FAILURE = "QR_DATA_FAILURE";
export const GET_ORDER_REQUEST = "GET_ORDER_REQUEST";
export const GET_ORDER_SUCCESS = "GET_ORDER_SUCCESS";
export const GET_ORDER_FAILURE = "GET_ORDER_FAILURE";
export const UPDATE_ORDER_REQUEST = "UPDATE_ORDER_REQUEST";
export const UPDATE_ORDER_SUCCESS = "UPDATE_ORDER_SUCCESS";
export const UPDATE_ORDER_FAILURE = "UPDATE_ORDER_FAILURE";


// ============= Touchless Vending ============= //

export const START_SESSION = "START_SESSION";
export const STOP_SESSION = "STOP_SESSION";

export const SET_TOUCHLESS_NEXT_PAGE = "SET_TOUCHLESS_NEXT_PAGE";
export const SET_TOUCHLESS_PEVIOUS_PAGE = "SET_TOUCHLESS_PEVIOUS_PAGE";
export const SET_TOUCHLESS_PAGE_COUNT = "SET_TOUCHLESS_PAGE_COUNT";
export const SET_TOUCHLESS_PAGE_LIMIT = "SET_TOUCHLESS_PAGE_LIMIT";
export const SET_TOUCHLESS_LOGO = "SET_TOUCHLESS_LOGO";

export const SET_PRODUCTS = "SET_PRODUCTS";
export const SET_THEME = "SET_THEME";
export const SET_SHOPPING_CART_LIMIT = "SET_SHOPPING_CART_LIMIT";

export const SET_CURRENCY = "SET_CURRENCY";
export const ADD_PRODUCT_TO_CART = "ADD_PRODUCT_TO_CART";
export const ADD_PRODUCT_QUANTITY = "ADD_PRODUCT_QUANTITY";
export const DECREASE_PRODUCT_QUANTITY = "DECREASE_PRODUCT_QUANTITY";
export const REMOVE_PRODUCT_FROM_CART = "REMOVE_PRODUCT_FROM_CART";
export const SET_CATEGORIES = "SET_CATEGORIES";
export const FLASH_CART = "FLASH_CART";

export const NEXT_PAGE = "NEXT_PAGE";
export const PREVIOUS_PAGE = "PREVIOUS_PAGE";
export const JUMP_PAGE = "JUMP_PAGE";

export const DECREASE_TIMER = "DECREASE_TIMER";
export const INITIALIZE_TIMER = "INITIALIZE_TIMER";

export const START_TOUCHLESS_LOADING = "START_TOUCHLESS_LOADING";
export const STOP_TOUCHLESS_LOADING = "STOP_TOUCHLESS_LOADING";

export const SET_ERROR = "SET_ERROR";

// ============= ***************** ============= //

// ============= IGT Constants ============= //
export const START_IGT_LOADING = "START_IGT_LOADING";
export const STOP_IGT_LOADING = "STOP_IGT_LOADING";
export const SET_IGT_CATEGORIES = "SET_IGT_CATEGORIES";
export const SET_IGT_PRODUCTS = "SET_IGT_PRODUCTS";
export const SET_IGT_ERROR = "STOP_IGT_LOADING";
export const ADD_IGT_PRODUCT_TO_CART = "ADD_IGT_PRODUCT_TO_CART";
export const ADD_IGT_PRODUCT_QUANTITY = "ADD_IGT_PRODUCT_QUANTITY";
export const DECREASE_IGT_PRODUCT_QUANTITY = "DECREASE_IGT_PRODUCT_QUANTITY";
export const REMOVE_IGT_PRODUCT_FROM_CART = "REMOVE_IGT_PRODUCT_FROM_CART";
export const SET_IGT_CATEGORIES_INFO = "SET_IGT_CATEGORIES_INFO";
export const INITIALIZE_IGT_TIMER = "INITIALIZE_IGT_TIMER";
export const DECREASE_IGT_TIMER ="DECREASE_IGT_TIMER";
export const STOP_IGT_SESSION ="STOP_IGT_SESSION";
export const UPDATE_CHECKOUT_RESPONSE_DATA = "UPDATE_CHECKOUT_RESPONSE_DATA"
export const SET_IGT_USED_SESSION = "SET_IGT_USED_SESSION"
export const RESET_DATA ="RESET_DATA"
// ============= ***************** ============= //


// Company Settings
export const SET_ENABLE_TAXES_PER_VM = "SET_ENABLE_TAXES_PER_VM";

// Product Setting
export const SET_ENABLE_PRODUCT_DROPDOWN = "SET_ENABLE_PRODUCT_DROPDOWN";
