
import React, { useState } from "react";
import { useSnackbar } from "notistack";
import { FileUploadInfoButton } from "../../Buttons";
import ImagesModal from "../../../Product/images_info";

const FileDetails = ({ field = {}, onChange = () => { },fieldValues }) => {

  const [fileName, setFileName] = useState(fieldValues?.['images']?.length || "");
  const [openModal, setOpenModal] = useState(false)
  const [checkExtension, setCheckExtension] = useState(true);
  const [imagesData, setImagesData] = useState(fieldValues?.['images'] || [])
  const [allImages, setAllImages] = useState([])
  const { enqueueSnackbar } = useSnackbar();

  const getImages = (field, e) => {
    let images = []
    for(let i = 0; i < e.length; i++)
    {
      images.push(e[i])
      
    }
    setImagesData(images?.map(x => [URL.createObjectURL(x), x.name]))
    setFileName(images.length)
    setAllImages(images)
  };

  const removeImages = (val) => {
    let images = []
    for(let i = 0; i < allImages.length; i++)
    {
      if(allImages[i].name !== val[1]) images.push(allImages[i])
    }
    onChange(field, images)
    setFileName(images.length)
    setImagesData(images?.map(x => [URL.createObjectURL(x), x.name]))
    setAllImages(images)
  }



  return (
    <>
    <div style={{ width: '100%' }} className={field.align ? "mb-2" : "mb-2 d-flex justify-content-end"}>
      <div style={{ width: '100%' }}>
        <FileUploadInfoButton
          tabIndex={field?.inputProps?.tabIndex}
          uploaded={fileName !== "" && fileName !== 0}
          size="small"
          label={field.formLabel || field.label || "UPLOAD FILE"}
          style={{ whiteSpace: 'nowrap' }}
          open={() => setOpenModal(true)}
          value={imagesData}
          className="mr-2"
        />
        <label style={{ fontSize: 13, display: "block", color: "#858585", }}>{field.hint}</label>
        {
          checkExtension ?
          <div style={{ fontSize: 11, maxWidth: 200, overflowWrap: 'break-word', marginTop: 5 }}>{fileName ? fileName > 1 ? `${fileName} Files uploaded successfully` : `${fileName} File uploaded successfully` : ""}</div>
          :
          ""
        }
      </div>
    </div>

    <ImagesModal
        title="ADDITIONAL IMAGES"
        data={imagesData || []}
        imagesData={allImages}
        open={openModal}
        close={() => setOpenModal(false)}
        field={field}
        onChange={onChange}
        fieldValues={fieldValues}
        getImages={getImages}
        removeImages={removeImages}
        info={true}
        changeIcon={false}
    />
    </>
  );
};

export default FileDetails;
